import { createSlice } from "@reduxjs/toolkit";

const lmsProgressReducerSlice = createSlice({
  name: "lmsProgress",
  initialState: {},
  reducers: {
    setProgress: (state, action) => {
      state = action.payload;
      return state;
    },
    clearProgress: (state) => {
      state = {};
      return state;
    },
  },
});

export const { setProgress, clearProgress } = lmsProgressReducerSlice.actions;
export default lmsProgressReducerSlice.reducer;
