export const Theme = {
  colors: {
    iconBgGrey: "#EFEFF4",
    grey: "#D1D1D6",
    lightGrey: "#e4e4e4",
    bgGrey: "rgba(0, 0, 0, 0.1)",
    footerBG: "rgba(0, 0, 0, 0.02)",
    white: "#fff",
    black: "#000000",
    orange: "#FF9500",
    green: "#4CD964",
    darkTextGreen: "#067519",
    absoluteGreen: "#008000",
    textgreen: "#4fa45d",
    lightGreen: "#90EE90",
    darkRed: "#AC0900",
    red: "#FF3B30",
    lightRed: "#FFCCCB",
    textGrey: "#8E8E93",
    lightTextGrey: "#6E6E6E",
    pillTextGrey: "#272727",
    textDarkGrey: "#6d6d6f",
    textDarkerGrey: "#707074",
    backgroundGrey: "#F5F5F5",
    yellow: "#FFCC00",
    themeYellow: "#F3CA20",
    darkTextYellow: "#846900",
    blue: "#1976d2",
    discordBlue: "#5865F2",
    lightBlue: "#e4f2ff",
    highlightedBlue: "#15416c",
    borderGray: "#d8d8d8",
    lightPurple: "#ac59bf3d",
    bgLightPurple: "#f8f1ff",
    darkPurple: "#4039a8",
    purple: "#9100ff",
    lightYellow: "#ffe370",
    bgYellow: "#fcf9de",
    deepYellow: "#ae8c03",
    highlightYellow: "#fff4c7",
    fadedYellow: "#ffe88c",
    muiRed: "#FF3B30",
    bgAqua: "#e8fcfc",
    bgLightBlue: "#F3F8FF",
    bgLightBlue2: "#dfecff",
    darkGray: "#A9A6A6",
    dimGray: "#525769",
    darkCyanBlue: "#0072b1",
    themeBlue: "#3f57cb",
    whiteSmoke: "#f5eeee",
    lightBgYellow: "rgba(248, 247, 244, 1)",
    veryLightGray: "rgba(249, 249, 249, 1)",
    lightBgGreen: "#e5f8e5",
    seoLightBgYellow: "#fff9e7",
    darkGreen: "#00431b",
    textGreen: "#4fa45d",
    darkMud: "#b35a04",
    lightSkypeBlue: "#b3e7fb",
    periwinkle: "#CCCCFF",
    celadon: "#AFE1AF",
    khaki: "#F0E68C",
    blueGray: "#7393B3",
    bgLightPink: "#fff1fe",
    deepPink: "#f233f5",
    bgLightRed: "#fff1f5",
    deepRed: "#cc0621",
    deepMagenta: "#50012e",
    lightBGGrey: "#f0f5f6",
    canvaPurple: "#8b3dff",
    bgLightOrange: "#fff5e6",
    borderOrange: "#ffe2b8",
    textDarkOrange: "#8c4500",
    bgOrange: "#b36000",
    monacoBlack: "#1e1e1e"
  },
  fontSize: {
    xxxs: "8px",
    xxs: "10px",
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    xxl: "22px",
    xxxl: "24px",
    xxxxl: "35px",
    "5xl": "42px",
  },
  fontWeight: {
    light: "300",
    regular: "400",
    medium: "500",
    semiBold: "600",
    midBold: "700",
    bold: "900",
  },
  borderRadius: {
    xxxs: "4px",
    xxs: "8px",
    xs: "12px",
    sm: "16px",
    md: "20px",
    lg: "22px",
    xl: "24px",
    round: "50%",
  },
  breakpoints: {
    xxs: 0,
    xs: 300,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};
