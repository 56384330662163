import globals from "../../Globals";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";

const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

const isTestEmail = (email) => {
  if (globals.REACT_APP_ENV === "production") {
    let isOutscalEmail = email.includes("outscal");
    let isTestEmail = email.includes("test");
    return isOutscalEmail && isTestEmail;
  }
  return false;
};

function isValidEmail(email) {
  // Regular expression to check for valid email
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

const isValidNumber = (value) => {
  {
    if (value.number && value.country_code) {
      let phoneNumber = parsePhoneNumber(
        value.number?.toString(),
        value.country_code
      );
      if (phoneNumber) {
        return (
          phoneNumber.isValid() ||
          isValidPhoneNumber(value.number?.toString(), value.country_code)
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
};
export { validateEmail, isTestEmail, isValidEmail, isValidNumber };
