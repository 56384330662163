import { PopupTemplate } from "@/outscal-commons-frontend/Styled";
import React from "react";
import {
  CTA,
  CrossIcon,
  HTMLMessage,
  Header,
  Message,
  Root,
  Title,
  MessageImage,
} from "./MessagePopup.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const MessagePopupView = ({
  title,
  hidePopups,
  message,
  action,
  ctaText,
  image,
  imageSize,
  htmlMessage,
  messageColor,
  messageFontSize,
  width,
  padding,
  maxWidth,
  dismissable = true,
  analyticsText,
}) => {
  return (
    <PopupTemplate
      setShowPopUp={() => {
        dismissable ? hidePopups() : () => {};
      }}
      padding={padding}
      maxWidth={maxWidth}
      width={width}
      height="fit-content"
      bgColor="white"
      overflow="hidden"
    >
      <Root>
        <CrossIcon
          onClick={() => hidePopups()}
          data-analytics={[
            UIElements.ICON,
            analyticsText ? `${analyticsText}-cancel` : "message-popup",
          ]}
        />
        {image && (
          <MessageImage
            height={imageSize}
            width={imageSize}
            style={{ objectFit: "contain" }}
            src={image}
          />
        )}
        {title && (
          <Header>
            <Title>{title}</Title>
          </Header>
        )}
        {message && (
          <Message
            messageColor={messageColor}
            messageFontSize={messageFontSize}
          >
            {message}
          </Message>
        )}
        {htmlMessage && (
          <HTMLMessage
            dangerouslySetInnerHTML={{ __html: htmlMessage }}
          ></HTMLMessage>
        )}
        {ctaText && (
          <CTA
            onClick={action}
            data-analytics={[
              UIElements.BUTTON,
              analyticsText ? analyticsText : "message-popup",
            ]}
          >
            {ctaText}
          </CTA>
        )}
      </Root>
    </PopupTemplate>
  );
};

export default MessagePopupView;
