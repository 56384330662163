import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import { createTheme } from "@mui/material/styles";

const materialTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#f3ca20",
    },
    secondary: {
      main: "#000000",
    },
    error: {
      main: "#FF3B30",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          // Customizing styles here
          backgroundColor: Theme.colors.fadedYellow,
          fontSize: "16px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Customizing styles here
          inset: "auto",
          margin: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "10px 9px",
          fontSize: "15px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "15px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#fff",
          color: "#000000",
        }),
      },
    },
  },
});

export default materialTheme;
