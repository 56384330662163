import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";

class UserCourseStatusAction {
  getUserCoursStatuses = async () => {
    try {
      const resp = await NetworkManager.get("/user-course-status/myCourses");

      return resp.data;
    } catch (error) {
      console.log("error : ", error);
    }
  };
}

export default new UserCourseStatusAction();
