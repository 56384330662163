import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { PiSealCheckFill } from "react-icons/pi";

export const skillStatesData = {
  missingSkills: {
    icon: {
      icon: IoIosCheckmarkCircle,
      color: Theme.colors.textGrey,
      size: 16,
    },
    actionCTA: {
      text: "+ Add Skill",
      bgColor: Theme.colors.lightGrey,
      color: Theme.colors.black,
    },
  },
  unVerifiedSkills: {
    icon: {
      icon: IoIosCheckmarkCircle,
      color: Theme.colors.textGreen,
      size: 16,
    },
    actionLink: {
      text: "Verify Skill",
      link: "/courses#Assessment",
      bgColor: Theme.colors.lightBlue,
      color: Theme.colors.darkCyanBlue,
    },
  },
  verifiedSkills: {
    icon: {
      icon: PiSealCheckFill,
      color: Theme.colors.blue,
      size: 16,
    },
  },
};
