import React, { useState, useEffect } from "react";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { mobileNavbarItems } from "./mobileNavbar.model";
import MobileNavbarView from "./mobileNavbar.view";
import { useRouter } from "next/router";
import usePopupActions, { LOGIN_POPUP } from "@/store/actions/popupAction";
import useMobileBreakPoint from "@/hooks/useMobileBreakPoint";
import useAuthActions from "@/store/actions/authAction";
import { showScholarshipPlug } from "@/Services/scholarship";

const MobileNavbarController = ({ highlightedItems = [] }) => {
  const router = useRouter();
  const { user } = useUserActions();
  const { auth } = useAuthActions();
  const [headerItems, setHeaderItems] = useState(mobileNavbarItems);
  const [activeTab, setActiveTab] = useState("home");
  const isMobile = useMobileBreakPoint();
  const { showPopup, hidePopups } = usePopupActions();
  const showLoginPopup = () => {
    showPopup(LOGIN_POPUP, {});
  };

  const addLinksToNavbar = async () => {
    if (user?.userDetails?.username) {
      headerItems.profile.link = "/" + user.userDetails.username + "/profile";
    } else {
      headerItems.profile.link = "#";
    }
    setHeaderItems({ ...headerItems });
  };

  const addNotificationsVisibility = () => {
    const scholarshipPlugVisibility = showScholarshipPlug(user);
    let updatedHeaderItems = { ...headerItems };

    updatedHeaderItems.profile.notification.isVisible = !auth?.token;

    if (scholarshipPlugVisibility) {
      updatedHeaderItems.scholarship.isVisible = true;
      updatedHeaderItems.companies.isVisible = false;
    }

    if (router.pathname !== "/courses" && scholarshipPlugVisibility) {
      updatedHeaderItems.scholarship.notification.isVisible =
        !router.pathname.includes("scholarship");
    } else {
      updatedHeaderItems.scholarship.isVisible = false;
      updatedHeaderItems.companies.isVisible = true;
    }

    setHeaderItems(updatedHeaderItems);
  };

  const handleTabClick = (key) => {
    setActiveTab(key);
    if (key.includes("profile") && !user?.userDetails?.username) {
      showLoginPopup();
    } else {
      hidePopups();
    }
  };

  useEffect(() => {
    addLinksToNavbar();
  }, [user]);

  useEffect(() => {
    addNotificationsVisibility();
  }, [auth?.token, user, router.pathname]);

  useEffect(() => {
    let isActiveTab = "";
    const updatedHeaderItems = { ...headerItems };
    Object.keys(updatedHeaderItems).forEach((key) => {
      if (highlightedItems?.includes(updatedHeaderItems[key].id)) {
        updatedHeaderItems[key].isHighlighted = true;
      } else {
        updatedHeaderItems[key].isHighlighted = false;
      }

      if (
        (mobileNavbarItems[key].link === "/" && router.pathname === "/") ||
        (mobileNavbarItems[key].link !== "/" &&
          router.pathname.includes(mobileNavbarItems[key].link))
      ) {
        isActiveTab = key;
      }
    });

    setActiveTab(isActiveTab);
    setHeaderItems(updatedHeaderItems);
  }, []);

  const visibleHeaderItems = Object.fromEntries(
    Object.entries(headerItems).filter(([key, value]) => value.isVisible)
  );

  return (
    <MobileNavbarView
      showNavBar={isMobile}
      headerItems={visibleHeaderItems}
      activeTab={activeTab}
      onClick={handleTabClick}
      highlightedItems={highlightedItems}
    />
  );
};

export default MobileNavbarController;
