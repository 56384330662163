import styled from "styled-components";

export const StyledMain = styled.main`
  padding: 0px;
  margin: 0 auto;
`;

export const Div = styled.div`
  ${(p) => p.fitContentHeight && `height:fit-content`};
  ${(p) => p.fitContent && `width:fit-content`};
  ${(p) => p.display && `display:${p.display}`};
  ${(p) => p.flexWrap && `flex-wrap: ${p.flexWrap}`};
  ${(p) => p.padding && `padding:${p.padding}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.inline && `display:inline-block`};
  ${(p) => p.top && `margin-top: ${p.top}`};
  ${(p) => p.bottom && `margin-bottom: ${p.bottom}`};
  ${(p) => p.left && `margin-left: ${p.left}`};
  ${(p) => p.right && `margin-right: ${p.right}`};
  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};
  ${(p) => p.justifyContent && `text-align: ${p.justifyContent}`};
  ${(p) => p.width && `width: ${p.width}`};
  ${(p) => p.height && `height: ${p.height}`};
`;

export const RowDiv = styled(Div)`
  display: flex;
  flex-direction: row;
  ${(p) => p.wrap && `flex-wrap: wrap;`};
  ${(p) => p.gap && `gap: ${p.gap}`};
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent}`};
  ${(p) => p.flexDirection && `flex-direction: ${p.flexDirection}`};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
`;

export const ColDiv = styled(Div)`
  display: flex;
  flex-direction: column;
  ${(p) => p.gap && `gap: ${p.gap}`};
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent}`};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
`;

export const A = styled.a`
  text-decoration: none;
  ${(p) => p.padding && `padding:${p.padding}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.blue)};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.md};
  ${(p) => p.fontWeight && `font-weight: ${p.theme.fontWeight[p.fontWeight]}`};
  ${(p) => p.height && `height: ${p.height}`};
`;
