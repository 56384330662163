import {
  FILE_UPLOAD,
  TEXT,
} from "@/outscal-commons-frontend/FormBuilder/InputTypes";
import { validateFileType } from "@/outscal-commons-frontend/services";
import usePopupActions from "@/store/actions/popupAction";
import dynamic from "next/dynamic";
import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";
const PopupTemplate = dynamic(() =>
  import("@/outscal-commons-frontend/Styled/PopupTemplate")
);
const FormBuilder = dynamic(() =>
  import("@/outscal-commons-frontend/FormBuilder/FormUI")
);
import React from "react";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { H1 } from "@/outscal-commons-frontend/Styled/TextStyles";
import { useRouter } from "next/router";
import { UrlValidationString } from "@/outscal-commons-frontend/Forms/validation";

const Heading = "Complete Outscal Course Application";
const ResumeFormData = {
  linkedin: {
    heading: "Linkedin (Optional)",
    defaultValue: "",
    validation: Yup.string().matches(
      "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)",
      "Enter correct url!"
    ),
    inputType: TEXT,
    placeholder: "https://www.linkedin.com/in/username/",
  },
  github: {
    heading: "Github (Optional)",
    defaultValue: "",
    validation: Yup.string().matches(UrlValidationString, "Enter correct url!"),
    inputType: TEXT,
    placeholder: "https://www.github.com/username/",
  },
  portfolio_link: {
    heading: "Portfolio Link (Optional)",
    defaultValue: "",
    validation: Yup.string().matches(UrlValidationString, "Enter correct url!"),
    inputType: TEXT,
    placeholder: "https://www.my-portfolio.com",
  },
  resume: {
    name: "resume",
    heading: "Upload Resume (Optional)",
    inputType: FILE_UPLOAD,
    placeholder:
      "Please upload resume (File type allowed - doc, docx or pdf and max size allowed - 5 MB)",
    width: "100%",
    customData: {
      fileUploadApi: "/file-upload/un-auth-resumee",
      multiple: false,
      maxSize: 5,
    },
    validation: Yup.string().test(
      "file-type-validation",
      "Allowed extension is pdf ,doc or docx",
      (value) => validateFileType("resume", value)
    ),
    accept: ".pdf, .doc, .docx",
  },
};

const AdsJoinWaitlistPopup = ({ onResumeSubmit = () => {} }) => {
  const { hidePopups } = usePopupActions();
  const router = useRouter();

  const onSubmit = async (submitData) => {
    try {
      const formData = new FormData();
      formData.append("resume", submitData.resume);
      formData.append("linkedin", submitData.linkedin);
      formData.append("portfolio_link", submitData.portfolio_link);
      formData.append("github", submitData.github);
      formData.append("step", "step2");
      let data = JSON.parse(localStorage.getItem("ads_joinwaitlist"));
      const resp = await NetworkManager.post(
        "ads/join-waitlist",
        { data: submitData, step: "step2", email: data.email },
        "",
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
        true
      );

      if (resp.statusCode === 200) {
        toast.success("Details submitted, our team will contact to you soon");
        router.push("/course/" + data.courseSlug);
      } else {
        toast.error("Resume update failed");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while submitting resume");
    }
  };

  return (
    <PopupTemplate
      padding="30px"
      maxWidth="600px"
      width="90%"
      bgColor="white"
      height="fit-content"
      setShowPopUp={() => {
        let data = JSON.parse(localStorage.getItem("ads_joinwaitlist"));
        //  router.push("/course/" + data.courseSlug + "/modules");
        hidePopups();
      }}
    >
      <H1 align="center">{"Complete Waitlist"}</H1>
      <br />
      <FormBuilder
        Inputs={ResumeFormData}
        inputStyles={{ direction: "column" }}
        onSubmit={onSubmit}
      />
    </PopupTemplate>
  );
};

export default AdsJoinWaitlistPopup;
