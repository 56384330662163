import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { styled } from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import Link from "next/link";

export const SkillActionCTA = styled.p`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  padding: 5px 16px;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  color: ${(p) => p.textColor};
  cursor: pointer;
  font-size: ${(p) => p.theme.fontSize.sm};
  transition: all 0.3s ease-in;
  &:hover {
    background-color: ${(p) => p.bgColor};
  }
`;

export const SkillText = styled.p`
  font-size: ${(p) => p.theme.fontSize.sm};
`;

export const SkillActionLink = styled(Link)``;

export const CheckIcon = styled(FaCheckCircle)`
  color: ${(p) => p.theme.colors[p.hasSkill ? "textGreen" : "textGrey"]};
  padding: ${(p) => (p.hasSkill ? "7px 0" : "0")};
`;
