import { createSlice } from "@reduxjs/toolkit";
import { plugStateShowDetails, plugState } from "../actions/scholarshipAction";

let initialState = {
  plugMessage: plugStateShowDetails[plugState.inform].message,
  plugState: plugState.inform,
  plugLink: "/courses#Scholarships",
};

const scholarshipSlice = createSlice({
  name: "scholarshipPlugInfo",
  initialState,
  reducers: {
    setScholarshipData: (state, action) => {
      state = action.payload;
      return state;
    },
    clearScholarshipData: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { clearScholarshipData, setScholarshipData } =
  scholarshipSlice.actions;
export default scholarshipSlice.reducer;
