import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "locationList",
  initialState: [],
  reducers: {
    setLocationsList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearLocationsList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setLocationsList, clearLocationsList } = locationSlice.actions;
export default locationSlice.reducer;
