import React from "react";
import { RowDiv, ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import GoogleOAuthButton from "../Buttons/Auth/GoogleAuth/GoogleAuthButton.controller";
import { CtaText } from "./SocialAuthIcons.styles";

const SocialAuthIconsView = ({
  variant,
  width,
  isIconPill,
  analyticsIntentText,
  showText,
  text,
  bgColor,
  color,
  highlight,
  redirectUrl,
  registerFrom,
}) => {
  return (
    <ColDiv gap="12px" width="100%">
      {showText && (
        <CtaText fontSize="sm" color="lightTextGrey">
          {isIconPill ? "or continue with" : "or"}
        </CtaText>
      )}
      <RowDiv alignItems="center" justifyContent="center" gap="8px">
        <GoogleOAuthButton
          analyticsIntentText={analyticsIntentText}
          width={width}
          highlight={highlight}
          variant={variant}
          text={text}
          bgColor={bgColor}
          color={color}
          redirectUrl={redirectUrl}
          registerFrom={registerFrom}
        />
      </RowDiv>
    </ColDiv>
  );
};

export default SocialAuthIconsView;
