import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import Link from "next/link";
import { RowDiv, ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";

export const PopupWrapper = styled(ColDiv)`
  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;

export const CloseIcon = styled(IoMdClose)`
  color: ${(p) => p.theme.colors.grey};
  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.textGrey};
  }
`;

export const EditSkillsCta = styled(Link)`
  color: ${(p) => p.theme.colors.themeBlue};
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  &:hover {
    cursor: pointer;
  }
`;

export const EditProfileDiv = styled(RowDiv)`
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

