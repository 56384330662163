import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserDetails,
  setUserPreference,
  setUserProfile,
  setUserResume,
  setUserCourseStatus,
  setUserNotifications,
  clearUserDetails,
  setUserScholarships,
} from "../../reducers/userReducer";
import {
  AnalyticsManager,
  NetworkManager,
} from "@/outscal-commons-frontend/Managers";
import userScholarshipActionController from "./userScholarshipAction.controller";
import UserCourseStatusAction from "./userCourseStatusAction";
const USER_DATA_KEY = "userData";
var apiInProgress = false;
const useUserActions = () => {
  const dispatch = useDispatch();
  const setProfile = (profile) => {
    dispatch(setUserProfile(profile));
  };
  const clearDetails = () => {
    dispatch(clearUserDetails());
  };
  const user = useSelector((state) => state.user);
  const setPreference = (preference) => {
    dispatch(setUserPreference(preference));
  };

  const setResume = (resume) => {
    dispatch(setUserResume(resume));
  };

  const setDetails = (details) => {
    dispatch(setUserDetails(details));
  };

  const setCourseStatus = (courseStatus) => {
    dispatch(setUserCourseStatus(courseStatus));
  };

  const setScholarships = (scholarships) => {
    dispatch(setUserScholarships(scholarships));
  };

  const setNotifications = (notifications) => {
    dispatch(setUserNotifications(notifications));
  };

  const updateUserProfile = async (showLoading = false) => {
    apiInProgress = true;
    let resp = await NetworkManager.get(
      "/users/profile",
      undefined,
      undefined,
      showLoading
    );
    const savedProfile = resp?.data?.profile;
    AnalyticsManager.event({
      category: "UserPersona",
      eventName: "",
      action: savedProfile?.persona >= 0 ? savedProfile.persona : -1,
    });

    AnalyticsManager.setUserProps(savedProfile);
    if (resp.data) {
      if (resp.data.profile) {
        setProfile(resp.data.profile);
      }
      if (resp.data.preference) {
        setPreference(resp.data.preference);
      }
      if (resp.data.resume) {
        setResume(resp.data.resume);
      }
      if (resp.data?.details?.data) {
        setDetails(resp.data.details.data);
      }
      if (resp?.data?.userCourseStatus) {
        setCourseStatus(resp.data.userCourseStatus);
      }
      if (resp?.data?.notifications) {
        setNotifications(resp?.data?.notifications);
      }
      if (resp?.data?.userScholarships) {
        setScholarships(resp?.data?.userScholarships);
      }
    }
    apiInProgress = false;
  };

  const getAndSetUserProfile = async () => {
    if (localStorage.getItem(USER_DATA_KEY)) {
      updateUserProfile();
    }
  };

  const getAndSetUserScholarships = async () => {
    const userScholarships =
      await userScholarshipActionController.getUserScholarships();
    if (userScholarships) {
      setScholarships(userScholarships);
    }
  };

  const getAndSetUserCourseStatuses = async () => {
    const userCourseStatuses =
      await UserCourseStatusAction.getUserCoursStatuses();

    if (userCourseStatuses?.length > 0) {
      setCourseStatus(userCourseStatuses);
    }
  };

  useEffect(() => {
    if (!user.userDetails && !apiInProgress) {
      getAndSetUserProfile();
    }
  }, []);

  return {
    updateUserProfile,
    setProfile,
    setPreference,
    setResume,
    getAndSetUserProfile,
    setDetails,
    clearDetails,
    setCourseStatus,
    setNotifications,
    setScholarships,
    getAndSetUserScholarships,
    getAndSetUserCourseStatuses,
    user,
  };
};

export default useUserActions;
