import { createSlice } from "@reduxjs/toolkit";

const successStorySlice = createSlice({
  name: "successStory",
  initialState: {
    data: null,
  },
  reducers: {
    setSuccessStory: (state, action) => {
      state.data = action.payload;
      return state;
    },
  },
});

export const {
    setSuccessStory,
} = successStorySlice.actions;
export default successStorySlice.reducer;
