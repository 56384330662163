import {
  Button,
  ColDiv,
  GradientText,
  Text,
  YellowButton,
} from "@/outscal-commons-frontend/Styled";
import { FadeIn } from "@/outscal-commons-frontend/Styled/Animations";
import Link from "next/link";
import styled, { css } from "styled-components";

export const PopupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FormSection = styled.div`
  padding: 28px 40px;
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  text-align: center;

  @media (max-width: 600px) {
    padding: 24px 16px;
    width: inherit;
  }
`;


export const FeaturesList = styled(ColDiv)`
  display: ${(p) => (p.hasFeatures ? "inherit" : "none")};
`;

export const PopupSecondarySection = styled(ColDiv)`
  position: relative;
  gap: 12px;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 0 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("https://outscal-assets.s3.ap-south-1.amazonaws.com/web-stories/login_banner_3.png");
    background-size: cover;
    background-position: center;
    /* filter: brightness(1.1); */
    z-index: -1;
  }

  @media (max-width: 600px) {
    background-color: white;
    width: inherit;
    padding: 0 20px 20px 20px;
  }
`;

export const SecondaryCtaSpan = styled.span`
  @media (min-width: 600px) {
    display: none;
  }
`;

export const CtaSpan = styled.span`
  font-size: ${(p) => p.theme.fontSize[p.fontSize ? p.fontSize : "sm"]};
  color: ${(p) => p.theme.colors[p.color ? p.color : "black"]};
  font-weight: ${(p) => p.theme.fontWeight.medium};
`;

export const CtaSpanText = styled(CtaSpan)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const CtaLink = styled(Link)`
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) => p.theme.colors[p.color ? p.color : "black"]};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  &:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.colors[p.hoverColor ? p.hoverColor : "black"]};
  }
`;

export const CtaText = styled(Text)`
  font-size: ${(p) => p.theme.fontSize[p.fontSize ? p.fontSize : "md"]};
  color: ${(p) => p.theme.colors[p.color ? p.color : "black"]};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  cursor: pointer;
`;

export const SecondaryCtaText = styled(Text)`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.md};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};

  @media (max-width: 600px) {
    color: ${(p) => p.theme.colors.textGrey};
    font-size: ${(p) => p.theme.fontSize.sm};
    font-weight: ${(p) => p.theme.fontWeight.regular};
  }
`;

export const SecondaryCtaHeading = styled(Text)`
  text-align: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const SecondaryCtaButton = styled(Button)`
  width: fit-content;
  padding: 8px 14px 8px 14px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.borderRadius.xs};
  font-size: ${(p) => p.theme.fontSize.sm};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 3px 4px 4px 2px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.label`
  margin: auto;
  font-weight: ${(p) => p.theme.fontWeight.midBold};
  font-size: ${(p) => p.theme.fontSize["5xl"]};
  @media (max-width: 600px) {
    font-size: ${(p) => p.theme.fontSize.xxxxl};
  }
`;

export const GradientHeading = styled(GradientText)`
  margin: auto;
  font-weight: ${(p) => p.theme.fontWeight.midBold};
  font-size: ${(p) => p.theme.fontSize["5xl"]};
  @media (max-width: 600px) {
    font-size: ${(p) => p.theme.fontSize.xxxxl};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 10px;
`;

export const Form = styled.div`
  width: 100%;
`;

export const AnimatedForm = styled(Form)`
  width: 100%;
  display: none;
  ${(p) =>
    p.fade &&
    css`
      display: block;
      animation: ${FadeIn} 0.5s ease-in-out;
    `}
`;

export const SubmitButton = styled(YellowButton)`
  width: 100%;
  padding: 12px;
  background-color: ${(p) => p.theme.colors.yellow};
  border-radius: ${(p) => p.theme.borderRadius.xs};
  font-size: ${(p) => p.theme.fontSize.xl};
`;
