import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import jobRoleReducer from "./jobRoleReducer";
import techStackReducer from "./techStackReducer";
import gameGenreReducer from "./gameGenreReducer";
import jobTypeReducer from "./jobTypeReducer";
import locationTypeReducer from "./locationTypeReducer";
import jobSearchStatusReducer from "./jobSearchStatusReducer";
import popupReducer from "./popupReducer";
import lmsProgressReducer from "./lmsProgressReducer";
import lmsCourse from "./lmsCourseReducer";
import loadingReducer from "./loadingReducer";
import locationReducer from "./locationReducer";
import userTasksReducer from "./userTasksReducer";
import userCourseStatusReducer from "./userCourseStatusReducer";
import successStoryReducer from "./successStoryReducer";
import userCourseProgressReducer from "./userCourseProgressReducer";
import jobCategoryTagsReducer from "./jobCategoryTagsReducer";
import companyReducer from "./companyReducer";
import jobFiltersReducer from "./jobFiltersReducer";
import jobAlertsReducer from "./jobAlertsReducer";
import jobsReducer from "./jobsReducer";
import codeEditorReducer from "./codeEditorReducer";
import scholarshipReducer from "./scholarshipReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  jobRoleList: jobRoleReducer,
  techStackList: techStackReducer,
  gameGenreList: gameGenreReducer,
  jobTypeList: jobTypeReducer,
  locationTypeList: locationTypeReducer,
  locationList: locationReducer,
  jobSearchStatusList: jobSearchStatusReducer,
  popup: popupReducer,
  lmsProgress: lmsProgressReducer,
  lmsCourse: lmsCourse,
  loading: loadingReducer,
  userTasks: userTasksReducer,
  currentCourse: userCourseStatusReducer,
  successStory: successStoryReducer,
  userCourseProgress: userCourseProgressReducer,
  jobCategoryTagList: jobCategoryTagsReducer,
  companyList: companyReducer,
  jobFilters: jobFiltersReducer,
  jobAlerts: jobAlertsReducer,
  jobs: jobsReducer,
  codeEditor: codeEditorReducer,
  scholarshipPlugInfo: scholarshipReducer,
});

export const loadState = () => {
  try {
    let token = null;
    const user = window.localStorage.getItem("userData");

    if (user) {
      token = JSON.parse(user).token;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return {
      auth: { token },
      user: {
        userJobProfile: null,
        userPreference: null,
        userResume: null,
        userDetails: null,
      },
      jobRoleList: [],
      techStackList: [],
      gameGenreList: [],
      jobTypeList: [],
      locationList: [],
      locationTypeList: [],
      jobSearchStatusList: [],
      popup: {
        popup: urlParams.get("popup") ? urlParams.get("popup") : null,
        data: null,
      },
      jobCategoryTagList: [],
      companyList: [],
      jobAlerts: {},
      jobs: {},
      codeEditor: {
        code: ``,
        isFullscreen: false,
        isLoading: false,
        interactiveInput: false,
        codeCompilerTab: "input",
      },
    };
  } catch (err) {
    return undefined;
  }
};

export default rootReducer;
