import React from "react";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { CtaLink, CtaSpan, SecondaryCtaText } from "./AuthPopups.styles";

const PrivacyPolicyView = ({
  privacyPolicyText = "By clicking Agree & Join, you agree to the Outscal",
  analyticsText = "privacy_policy_opened",
}) => {
  return (
    <Div margin="-8px 0">
      <SecondaryCtaText>
        <CtaSpan color="textGrey">{privacyPolicyText} </CtaSpan>
        <CtaLink
          hoverColor="themeBlue"
          color="themeBlue"
          href="/privacy-policy"
          target="_blank"
          data-analytics={[UIElements.LINK, analyticsText]}
        >
          Privacy Policy
        </CtaLink>
      </SecondaryCtaText>
    </Div>
  );
};

export default PrivacyPolicyView;
