import Link from "next/link";
import styled, { css } from "styled-components";
import { NAV_WIDTH } from "./mobileNavbar.model";

export const NavBar = styled.div`
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px -3px 6px;
  width: ${NAV_WIDTH};
  height: 48px;
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 12px 0px 0px;
`;

export const CircularDiv = styled.div`
  position: absolute;
  z-index: 0;
  top: 0px;
  left: -10px;
  /* transform: translate(0%, -95%); */
  height: 2px;
  width: 80px;
  border-radius: 0px 0px 3px 3px;
  background-color: ${(p) => p.theme.colors.black};
  transition: all 300ms ease-in-out;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px -6px 6px; */
  /* &:before {
    content: "";
    position: absolute;
    background: transparent;
    border-radius: 50%;
    top: -1.5px;
    right: 35.5px;
    transform: translateX(-100%);
    width: 30px;
    height: 30px;
    box-shadow: 18px 20px ${(p) => p.theme.colors.white};
  }

  &:after {
    content: "";
    position: absolute;
    background: transparent;
    border-radius: 50%;
    top: -1.5px;
    right: -35.5px;
    transform: translateX(-35%);
    width: 30px;
    height: 30px;
    box-shadow: -18px 20px ${(p) => p.theme.colors.white};
  } */
`;

export const NavItemPill = styled(Link)`
  margin-top: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 0;
  text-align: center;
  font-weight: 500;
`;

export const IconContainer = styled.span`
  position: relative;
  display: block;
  line-height: 0px;
  font-size: ${(p) => p.theme.fontSize[p.iconSize ? p.iconSize : "xxxl"]};
  transition: 0.5s;
  color: ${(p) => p.theme.colors.textGrey};
  @media screen and (min-width: 600px) {
    flex-direction: row;
    font-size: ${(p) => p.theme.fontSize["lg"]};
  }
`;

export const LabelPill = styled.span`
  background-color: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.dimGray};
  font-size: ${(p) => p.theme.fontSize[p.fontSize ? p.fontSize : "xxxs"]};
  padding: 2px 6px 4px;
  border-radius: ${(p) => p.theme.borderRadius.md};
  transition: all 300ms ease-in-out;
`;

export const ItemRoot = styled.li`
  list-style: none;
  position: relative;
  width: ${(p) => `calc(${NAV_WIDTH} / ${p.itemsLength})`};
  height: 40px;
  z-index: 2;
  transition: all 300ms ease-in-out;
  &.active {
    color: ${(p) => p.theme.colors[p.color ? p.color : "themeBlue"]};
  }
  &.active ${LabelPill} {
    color: ${(p) => p.theme.colors.black};
    font-weight: 900;
  }
  &.active ${IconContainer} {
    scale: 1.2;
    opacity: 1;
    color: ${(p) => p.theme.colors[p.color ? p.color : "black"]};
  }

  &.highlighted ${LabelPill} {
    background: linear-gradient(
      45deg,
      ${(p) => p.theme.colors.themeBlue},
      ${(p) => p.theme.colors.canvaPurple}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.highlighted ${IconContainer} {
    color: ${(p) => p.theme.colors.themeYellow};
    path {
      stroke: ${(p) => p.theme.colors.black};
      stroke-width: 8px;
      filter: drop-shadow(16px 16px 8px rgba(0, 0, 0, 0.16));
    }
  }

  ${(p) =>
    Array.from({ length: p.itemsLength })
      .map(
        (_, index) =>
          `
          &:nth-child(${index + 1}).active ~ ${CircularDiv} {
      translate: calc(((${NAV_WIDTH} / ${
            p.itemsLength
          }) * ${index}) + ((${NAV_WIDTH} / ${p.itemsLength} - 93px) / 2));
    }
  `
      )
      .join("")}
`;
