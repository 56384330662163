import dynamic from "next/dynamic";
import { ApolloProvider } from "@apollo/client/react";
import "@/styles/globals.css";
import "@/styles/Collapsible.css";
import "@/styles/ImageNode.css";
import { useAmp } from "next/amp";
import { ThemeProvider } from "styled-components";
import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "../store/store";
import { useEffect } from "react";
import { AnalyticsManager } from "@/outscal-commons-frontend/Managers";
import materialTheme from "@/StyledComponents/MaterialTheme";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css";
import "@/styles/lexical-editor.scss";
import UseAnalyticsClickEvent from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import PopupService from "@/Services/popupService";
import useUtms from "@/hooks/useUtms";
import ApolloClientManager from "../Logic/GraphQL/ApolloClientManager";
import MobileNavbarService from "@/mvcComponents/Commons/Navbar/MobileNavbar/mobileNavbar.service";
import { useRouter } from "next/router";
// import AuthPopupComponentService from "@/Services/Auth/AuthPopupComponentService";
const Tooltip = dynamic(() =>
  import("react-tooltip").then((module) => module.Tooltip)
);
const Toast = dynamic(() => import("@/outscal-commons-frontend/Styled/Toast"));
const Header = dynamic(() => import("@/PageComponents/Header/Header"));

export const config = { amp: "hybrid" };
export default function App({ Component, pageProps }) {
  useUtms();
  const isAmp = useAmp();
  const router = useRouter();

  useEffect(() => {
    AnalyticsManager.checkAuth();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 600 && router.asPath.includes("job=")) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [router.asPath]);

  return (
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={ApolloClientManager.client}>
        <MaterialThemeProvider theme={materialTheme}>
          <Provider store={store}>
            {!isAmp && <Header />}
            {/* {!isAmp && <MessageBanner />} */}
            <Component {...pageProps} />
            {!isAmp && <MobileNavbarService />}
            {!isAmp && <Toast />}
            {!isAmp && <Tooltip id="data-tip" className="generic-tooltip" />}
            {!isAmp && <PopupService />}
            {/* {!isAmp && <AuthPopupComponentService type="google" />} */}
            {/* {!isAmp && <UserTasks />} */}
          </Provider>
          <UseAnalyticsClickEvent />
        </MaterialThemeProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}
