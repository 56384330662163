export const AllSkillAssessmentIds = [
  "63cbd350436b61c64480bf2e",
  "63cbd400436b61c64480bf51",
  "63cbd484436b61c64480bf5d",
  "63cbd4fe436b61c64480bf6d",
  "63ef570e7423d761ef520a66",
];

export const skillAssessmentMapping = {
  Unity: {
    assessment_title: "Unity",
    assessment_id: "63cbd350436b61c64480bf2e",
    assessment_slug: "unity-game-engine",
  },
  "Data Structures": {
    assessment_title: "DSA",
    assessment_id: "63cbd4fe436b61c64480bf6d",
    assessment_slug: "data-structures-and-algorithms",
  },
  Algorithms: {
    assessment_title: "DSA",
    assessment_id: "63cbd4fe436b61c64480bf6d",
    assessment_slug: "data-structures-and-algorithms",
  },
  "C++": {
    assessment_title: "C++",
    assessment_id: "63ef570e7423d761ef520a66",
    assessment_slug: "c",
  },
  OOPS: {
    assessment_title: "OOPS",
    assessment_id: "63cbd400436b61c64480bf51",
    assessment_slug: "object-oriented-programming",
  },
  "Programming patterns": {
    assessment_title: "Programming Patterns",
    assessment_id: "63cbd484436b61c64480bf5d",
    assessment_slug: "programming-patterns",
  },
};

export const defaultSkills = [
  "unity",
  "data-structures",
  "cpp",
  "programming-patterns",
  "oops",
  "algorithms",
];

export const passPercentage = 80;
