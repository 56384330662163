import { createSlice } from "@reduxjs/toolkit";

const jobSearchStatusSlice = createSlice({
  name: "jobSearchStatusList",
  initialState: [],
  reducers: {
    setJobSearchStatusList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearJobSearchStatusList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setJobSearchStatusList, clearJobSearchStatusList } =
  jobSearchStatusSlice.actions;
export default jobSearchStatusSlice.reducer;
