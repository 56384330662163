import React from "react";
import { toast } from "react-toastify";
import AddSkillCardView from "./AddSkillCard.view";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";
import { skillStatesData } from "./AddSkillCard.model";
import { passPercentage } from "@/staticData/Plugs/skillAssessments";

const AddSkillCard = ({
  techStack = {},
  assessmentResponse,
  skillSet,
  setUserSkillStates,
}) => {
  const { missingSkills, unVerifiedSkills, verifiedSkills } = skillStatesData;
  const { user, setProfile, getAndSetUserProfile } = useUserActions();

  const addTechStack = async (addedTechStack, missingSkills, matchedSkills) => {
    try {
      const resp = await NetworkManager.post("user/profile/add-tech-stack", {
        techStack: addedTechStack.id,
      });
      if (resp.statusCode === 200) {
        setUserSkillStates({ ...skillSet, missingSkills, matchedSkills });
        if (user?.userJobProfile) {
          setProfile({
            ...user?.userJobProfile,
            tech_stack: [
              ...user?.userJobProfile?.tech_stack,
              addedTechStack.id,
            ],
          });
        } else {
          getAndSetUserProfile();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCTAClick = async (addedTechStack) => {
    let addedTechStackLabel = addedTechStack?.label;
    let matchedSkills = [...skillSet?.matchedSkills];
    let missingSkills = [...skillSet?.missingSkills];

    matchedSkills.push(addedTechStackLabel);

    let removeIndex = missingSkills.indexOf(addedTechStackLabel);
    missingSkills.splice(removeIndex, 1);

    await addTechStack(addedTechStack, missingSkills, matchedSkills);

    toast.success("Skill added successfully");
  };

  let actionCTA = null;
  let actionLink = null;
  let icon = null;

  if (skillSet?.missingSkills?.includes(techStack?.label)) {
    actionCTA = missingSkills.actionCTA;
    icon = missingSkills.icon;
  } else {
    if (
      assessmentResponse &&
      assessmentResponse?.total_score >= passPercentage
    ) {
      icon = verifiedSkills.icon;
    } else {
      actionLink = unVerifiedSkills.actionLink;
      icon = unVerifiedSkills.icon;
    }
  }

  return (
    <AddSkillCardView
      techStack={techStack}
      onCTAClick={onCTAClick}
      actionCTA={actionCTA}
      actionLink={actionLink}
      icon={icon}
    />
  );
};

export default AddSkillCard;
