import { NetworkManager } from "@/outscal-commons-frontend/Managers";

class UserScholarshipActionController {
  async getUserScholarships() {
    const response = await NetworkManager.get("/user-scholarships");
    return response.data;
  }
}

export default new UserScholarshipActionController();
