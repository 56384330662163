import { createSlice } from "@reduxjs/toolkit";

const techStackSlice = createSlice({
  name: "techStackList",
  initialState: [],
  reducers: {
    setTechStackList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearTechStackList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setTechStackList, clearTechStackList } = techStackSlice.actions;
export default techStackSlice.reducer;
