import React from "react";
import {
  CommonElements,
  UIElements,
} from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import {
  IconContainer,
  ItemRoot,
  LabelPill,
  NavItemPill,
} from "./GeneralNavBar/mobileNavbarItem.styles";
import NotificationPill from "./NotificationPill/NotificationPill.controller";

const MobileNavbarItemView = ({
  id,
  icon: Icon,
  label = "",
  link = "",
  color = "",
  isActive,
  itemsLength = 5,
  onClick,
  notification,
  isHighlighted = false,
}) => {
  return (
    <ItemRoot
      onClick={onClick}
      className={isActive ? "active" : isHighlighted ? "highlighted" : ""}
      itemsLength={itemsLength}
      data-analytics={[
        UIElements.TAB,
        `mobile_navbar_${id}`,
        CommonElements.MOBILE_NAV,
      ]}
    >
      <NavItemPill href={link}>
        <IconContainer>
          <Icon />
          <NotificationPill notification={notification} />
        </IconContainer>
        <LabelPill>{label}</LabelPill>
      </NavItemPill>
    </ItemRoot>
  );
};

export default MobileNavbarItemView;
