import { createSlice } from "@reduxjs/toolkit";

const jobFiltersSlice = createSlice({
  name: "jobFilters",
  initialState: {
    isInitialized: false,
    filters: {},
    hiddenJobFilters: {},
    appliedFilters: {},
    fixedJobFilters: {},
  },
  reducers: {
    setHiddenFilters: (state, action) => {
      state.hiddenJobFilters = action.payload;
      return state;
    },
    setFixedFilters: (state, action) => {
      state.fixedJobFilters = action.payload;
      return state;
    },
    setAppliedFilters: (state, action) => {
      state.appliedFilters = { ...state.filters };
      return state;
    },
    initialize: (state, action) => {
      state.filters = action.payload;
      state.appliedFilters = action.payload;
      state.isInitialized = true;
      return state;
    },
    setJobFilters: (state, action) => {
      state.filters = action.payload;
      return state;
    },
    setJobFilterKey: (state, action) => {
      state.filters[action.payload.name] = action.payload.value;
      return state;
    },
    clearJobFilters: (state) => {
      return {
        ...state,
        appliedFilters: {},
        filters: {},
        fixedJobFilters: {}
      };
    },
  },
});

export const {
  initialize,
  setJobFilters,
  clearJobFilters,
  setJobFilterKey,
  setHiddenFilters,
  setFixedFilters,
  setAppliedFilters,
} = jobFiltersSlice.actions;
export default jobFiltersSlice.reducer;
