import { useRouter } from "next/router";
import { useEffect } from "react";

const IntentMap = {
  course: "COURSE",
  job: "JOB",
  company: "COMPANY",
};

export default function useUtms() {
  const router = useRouter();
  let utmObj = {};
  const SetUtms = ({ query }) => {
    let localUtmObj = {};
    if (typeof localStorage != "undefined") {
      localUtmObj = localStorage.getItem("utm");
      if (localUtmObj) {
        localUtmObj = JSON.parse(localUtmObj);
      } else {
        localUtmObj = {};
      }
    }
    let referrer_url = "direct";
    if (localUtmObj["utm_referrer_url"]) {
      referrer_url = localUtmObj["utm_referrer_url"];
    }
    if (
      referrer_url == "direct" &&
      typeof document !== "undefined" &&
      document?.referrer
    ) {
      referrer_url = document?.referrer;
    }

    let utm_referrer_url = referrer_url;

    let utm_source = query.utm_source || localUtmObj["utm_source"];
    let utm_medium = query.utm_medium || localUtmObj["utm_medium"];
    let utm_term = query.utm_term || localUtmObj["utm_term"];
    let utm_campaign = query.utm_campaign || localUtmObj["utm_campaign"];
    let utm_content = query.utm_content || localUtmObj["utm_content"];

    if (utm_source) {
      utmObj["utm_source"] = utm_source;
    }
    if (utm_campaign) {
      utmObj["utm_campaign"] = utm_campaign;
    }
    if (utm_medium) {
      utmObj["utm_medium"] = utm_medium;
    }
    if (utm_content) {
      utmObj["utm_content"] = utm_content;
    }
    if (utm_term) {
      utmObj["utm_term"] = utm_term;
    }

    if (utm_referrer_url) {
      utmObj["utm_referrer_url"] = utm_referrer_url;
    }
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("utm", JSON.stringify(utmObj));
    }

    return utmObj;
  };

  const GetUtms = () => {
    if (typeof localStorage !== "undefined") {
      const utm = localStorage.getItem("utm");
      const utmObj = utm ? JSON.parse(utm) : {};

      return utmObj;
    }
  };
  const GetLandingIntent = () => {
    if (typeof localStorage !== "undefined") {
      const landingIntent = localStorage.getItem("landingIntent");
      const obj = landingIntent ? JSON.parse(landingIntent) : {};
      return obj;
    }
  };

  const GetRetargetingIntent = () => {
    if (typeof sessionStorage !== "undefined") {
      const retargetingIntent = sessionStorage.getItem("retargetingIntent");
      const obj = retargetingIntent ? JSON.parse(retargetingIntent) : {};
      return obj;
    }
  };
  const GetIntentFromRouter = (router) => {
    let intent = "other";
    Object.keys(IntentMap).map((key) => {
      if (router.pathname.includes(key)) {
        intent = IntentMap[key];
      }
    });
    let url = router.asPath;
    const intentObj = { intent, url };
    return intentObj;
  };
  const setIntent = (router) => {
    let intent = "other";
    Object.keys(IntentMap).map((key) => {
      if (router.pathname.includes(key)) {
        intent = IntentMap[key];
      }
    });
    let url = router.asPath;

    let referrer_url = "direct";
    if (
      referrer_url == "direct" &&
      typeof document !== "undefined" &&
      document?.referrer
    ) {
      referrer_url = document?.referrer;
    }
    if (url.includes("?")) {
      url = url + "&utm_referrer_url=" + referrer_url;
    } else {
      url = url + "?utm_referrer_url=" + referrer_url;
    }

    const intentObj = { intent, url };
    if (
      typeof localStorage !== "undefined" &&
      !localStorage.getItem("landingIntent")
    ) {
      localStorage.setItem("landingIntent", JSON.stringify(intentObj));
    }

    if (
      typeof sessionStorage !== "undefined" &&
      !sessionStorage.getItem("retargetingIntent")
    ) {
      sessionStorage.setItem("retargetingIntent", JSON.stringify(intentObj));
    }
  };
  const clearUtmFromLocal = () => {
    if (typeof localStorage !== "undefined") {
      localStorage.removeItem("utm");
    }
  };
  useEffect(() => {
    SetUtms({ query: router.query });
    setIntent(router);
  }, [router.query, router]);

  return {
    GetRetargetingIntent,
    clearUtmFromLocal,
    GetUtms,
    SetUtms,
    GetLandingIntent,
    GetIntentFromRouter,
  };
}
