import { Div, H1 } from "@/outscal-commons-frontend/Styled";
import styled from "styled-components";

export const Root = styled(Div)`
  padding: 20px 20px;
`;

export const Heading = styled(H1)`
  font-size: ${(p) => p.theme.fontSize.xl};
  text-align: center;
  margin-bottom: 0.7rem;
`;

export const SubHeading = styled.p`
  font-family: sans-serif;
  text-align: center;
  margin-bottom: 1.2rem;
  color: #525252;
  font-weight: 500;
`;
