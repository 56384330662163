import { keyframes } from "styled-components";

export const FadeIn = keyframes`
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
`;

export const FadeOut = keyframes`
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
`;

export const shine = (
  color1 = "#F3CA20",
  color2 = "#fff1c7",
  color3 = "#fff9e7"
) => keyframes`
  0% {
    background: linear-gradient(
      30deg, 
      ${color1} 0%, 
      ${color3} 100%
    );
  }

  25% {
    background: linear-gradient(
      30deg, 
      ${color1} 0%, 
      ${color2} 25%, 
      ${color1} 50%
    );
  }

  50% {
    background: linear-gradient(
      30deg, 
      ${color1} 0%, 
      ${color2} 50%, 
      ${color1} 75%
    );
  }

  75% {
    background: linear-gradient(
      30deg, 
      ${color1} 0%, 
      ${color2} 75%, 
      ${color1} 100%
    );
  }

  100% {
    background: linear-gradient(
      30deg, 
      ${color1} 0%,  
      ${color3} 100%
    );
  }
`;


export const pulsate = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
`;

export const highlightAnimation = (scaleRatio, bgColor) => keyframes`
  0% {
    transform: scale(1);
    background-color: white;
  }
  50% {
    transform: scale(${scaleRatio});
    background-color: ${bgColor};
  }
  100% {
    transform: scale(1);
    background-color: white;
  }
`;

export const rotate = keyframes`
  0% { transform: rotate(0deg); }
  33.33% { transform: rotate(360deg); }
  100% { transform: rotate(360deg); }
`;

export const translateAnimation = (
  displacement = "6px",
  degree = 0
) => keyframes`
0% {
  transform: translateX(0) rotate(${degree}deg);
}
50% {
  transform: translateX(${displacement}) rotate(${degree}deg);
}
100% {
  transform: translateX(0) rotate(${degree}deg);
}
`;

export const scrollAnimation = (scrollDistance) => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-${scrollDistance}px);
  }
`;

export const slideDown = keyframes`
  from {
    transform: translateY(-100%);
   }
  to {
    transform: translateY(0);
   }
`;
