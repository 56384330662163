export const UrlValidationString =
  "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";

export const LoomUrlValidation =
  /https:\/\/www\.loom\.com\/share\/[a-zA-Z0-9]+/;

export const EmailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const UniversityEmailValidation =
  "@[a-zA-Z0-9._%+-]+\\.(edu|ac)(\\.[a-zA-Z]{2,})?$";
