import styled, { css } from "styled-components";
import Link from "next/link";
import Image from "next/image";
import { shine } from "@/outscal-commons-frontend/Styled/Animations";

export const GoogleButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) =>
    p.theme.colors[p.highlight ? "themeYellow" : p.bgColor]};
  padding: ${(p) => (p.isIconPill ? "12px" : "8px 16px")};
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  border-radius: ${(p) => p.theme.borderRadius[p.isIconPill ? "round" : "xxs"]};
  color: ${(p) => p.theme.colors[p.highlight ? "black" : p.color]};
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s ease;
  width: ${(p) => p.width};

  &:hover {
    ${(p) =>
      p.highlight
        ? css`
            transform: translateY(-3px);
            animation: ${shine()} 0.2s;
          `
        : css`
            background-color: ${p.theme.colors.backgroundGrey};
          `}
  }

  img {
    margin-right: ${(p) => (p.isIconPill ? "0" : "8px")};
  }
`;

export const StyledImage = styled(Image)``;
