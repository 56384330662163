import { createSlice } from "@reduxjs/toolkit";

const jobAlertsSlice = createSlice({
  name: "jobAlerts",
  initialState: { jobAlerts: {} },
  reducers: {
    setJobAlerts: (state, action) => {
      return {
        ...state,
        jobAlerts: action.payload,
      };
    },
  },
});

export const { setJobAlerts } = jobAlertsSlice.actions;
export default jobAlertsSlice.reducer;
