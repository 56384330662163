import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "popups",
  initialState: {
    popup: null,
    data: null,
  },
  reducers: {
    setPopup: (state, action) => {
      state.popup = action.payload.popup;
      state.data = action.payload.data;
      return state;
    },
    clearPopups: (state) => {
      return {
        popup: null,
        data: null,
        setOpen: null,
      };
    },
  },
});

export const { setPopup, clearPopups } = authSlice.actions;
export default authSlice.reducer;
