export const allowedFileType = {
  image: ["png", "jpg", "gif", "jpeg"],
  resume: ["doc", "docx", "pdf"],
  pdf: ["pdf"],
  documents: ["pdf", "doc", "docx"],
};

export const validateFileType = async (type, file) => {
  if (!file) {
    return true;
  }

  const isGoogleUserContentImage = file.includes("lh3.googleusercontent.com");

  let fileExtension;
  if (isGoogleUserContentImage) {
    try {
      const response = await fetch(file, { method: "HEAD" });
      const contentType = response.headers.get("Content-Type");
      fileExtension = contentType.split("/")[1];

    } catch (error) {
      console.error("Failed to fetch image type:", error);
      return false;
    }
  } else {
    const lastPeriodIndex = file.lastIndexOf(".");
    fileExtension = file.slice(lastPeriodIndex + 1).toLowerCase();
  }

  return allowedFileType[type] && allowedFileType[type].includes(fileExtension);
};
