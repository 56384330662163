import React from "react";
import SocialAuthIcons from "@/mvcComponents/Commons/SocialAuthIcons/SocialAuthIcons.controller";
import { ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { CtaSpan, CtaSpanText, SecondaryCtaText } from "../AuthPopups.styles";

const ProRegisterPopupActionsView = ({
  toggleToEmailRegister,
  analyticsIntentText,
  socialAuthButtonText,
  setToggleToEmailRegister,
  showLoginPopup,
  showLoginCta,
  registerFrom,
}) => {
  return (
    <>
      <SocialAuthIcons
        analyticsIntentText={analyticsIntentText}
        showText={false}
        text={socialAuthButtonText}
        width="100%"
        highlight={!toggleToEmailRegister}
        registerFrom={registerFrom}
      />
      <ColDiv gap="8px">
        {!toggleToEmailRegister && (
          <CtaSpanText
            color="themeBlue"
            onClick={() => setToggleToEmailRegister(true)}
            data-analytics={[
              UIElements.LINK,
              `register_with_email${analyticsIntentText}`,
            ]}
          >
            Register with email{" "}
          </CtaSpanText>
        )}
        {(showLoginCta || toggleToEmailRegister) && (
          <SecondaryCtaText>
            <CtaSpan color="textGrey">Already have an account? </CtaSpan>
            <CtaSpanText
              color="themeBlue"
              onClick={(e) => {
                showLoginPopup(e);
              }}
              data-analytics={[
                UIElements.LINK,
                `login_in_register_popup${analyticsIntentText}`,
              ]}
            >
              Login
            </CtaSpanText>
          </SecondaryCtaText>
        )}
      </ColDiv>
    </>
  );
};

export default ProRegisterPopupActionsView;
