import React from "react";
import styled from "styled-components";
import MobileNavbarItemView from "../mobileNavbarItem.view";
import { CircularDiv, NavBar } from "./mobileNavbarItem.styles";
const RootParent = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0px;
  width: 100%;
  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const UList = styled.ul`
  display: flex;
  padding: 0px;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MobileNavbarView = ({ headerItems, activeTab, onClick, showNavBar }) => {
  let activeIndex = null;
  let length = Object.keys(headerItems).length;
  return (
    <>
      {showNavBar && (
        <RootParent>
          <NavBar>
            <UList>
              {Object.keys(headerItems).map((tabKey, i) => {
                const {
                  id,
                  icon,
                  label,
                  link,
                  color,
                  notification,
                  isHighlighted,
                } = headerItems[tabKey];
                if (id === activeTab) {
                  activeIndex = i;
                }
                return (
                  <MobileNavbarItemView
                    itemsLength={length}
                    onClick={() => onClick(tabKey)}
                    key={tabKey}
                    id={id}
                    isActive={id === activeTab}
                    icon={icon}
                    label={label}
                    link={link}
                    color={color}
                    isHighlighted={isHighlighted}
                    notification={notification}
                  />
                );
              })}
              <li style={{ listStyleType: 'none'}}>{activeTab && <CircularDiv itemsLength={length} />}</li>
            </UList>
          </NavBar>
        </RootParent>
      )}
    </>
  );
};

export default MobileNavbarView;
