import { createSlice } from "@reduxjs/toolkit";

const jobCategoryTagsSlice = createSlice({
  name: "jobCategoryTags",
  initialState: [],
  reducers: {
    setJobCategoryTagsList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearJobCategoryTagsList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setJobCategoryTagsList, clearJobCategoryTagsList } =
  jobCategoryTagsSlice.actions;

export default jobCategoryTagsSlice.reducer;
