import { createSlice } from "@reduxjs/toolkit";

const lmsCourseReducerSlice = createSlice({
  name: "lmsCourse",
  initialState: {},
  reducers: {
    setCourse: (state, action) => {
      state = action.payload;
      return state;
    },
    clearCourse: (state) => {
      state = {};
      return state;
    },
  },
});

export const { setCourse, clearCourse } = lmsCourseReducerSlice.actions;
export default lmsCourseReducerSlice.reducer;
