import { createSlice } from "@reduxjs/toolkit";

const userTasksSlice = createSlice({
  name: "userTasks",
  initialState: [],
  reducers: {
    setTasks: (state, action) => {
      state = action.payload;
      return state;
    },
    clearTasks: (state) => {
      state = [];
      return state;
    },
  },
});

export const { setTasks, clearTasks } = userTasksSlice.actions;
export default userTasksSlice.reducer;
