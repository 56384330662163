import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken, clearAuthToken } from "@/store/reducers/authReducer";
import nookies from "nookies";

const USER_DATA_KEY = "userData";

const useAuthActions = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const setToken = (token, userData) => {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify({ ...userData, token }));
    nookies.set(null, "authToken", token, {
      maxAge: 365 * 24 * 60 * 60, // 30 days
      path: "/",
    });
    dispatch(setAuthToken(token));
  };
  const clearToken = () => {
    nookies.destroy(null, "authToken", {
      path: "/",
    });
    localStorage.removeItem("authToken");
    dispatch(clearAuthToken());
  };

  return { setToken, clearToken, auth };
};

export default useAuthActions;
