import axios from "axios";
import globals from "../../Globals";
import { toast } from "react-toastify";
const USER_DATA_KEY = "userData";

var onGoingRequests = {};

class NetworkManager {
  constructor() {
    this.pendingRequests = {};
    this.instance = axios.create({
      baseURL: globals.apiUrl,
    });
    this.setLoading = null;
    // this.setupDebounce();
  }

  setupDebounce = () => {
    this.instance.interceptors.request.use((config) => {
      const requestKey = `${config.method}${config.url}`;
      if (this.pendingRequests[requestKey]) {
        return this.pendingRequests[requestKey];
      }

      axios(config).finally(() => {
        delete this.pendingRequests[requestKey];
      });

      this.pendingRequests[requestKey] = config;
      return config;
    });
  };

  getToken = () => {
    let userDataStr;
    if (typeof window !== "undefined") {
      userDataStr = JSON.parse(
        sessionStorage.getItem(USER_DATA_KEY) ||
          localStorage.getItem(USER_DATA_KEY)
      );
    }
    return userDataStr ? userDataStr.token : null;
  };

  get = async (api, params = "", query = {}, showLoading = false) => {
    const resp = await this.#showLoading(async () => {
      let key = "get" + api + params + JSON.stringify(query);
      if (!onGoingRequests[key]) {
        onGoingRequests[key] = new Promise(async (resolve) => {
          const resp = await this.getData(api, params, query);
          delete onGoingRequests[key];
          resolve(resp);
        });
      }
      return onGoingRequests[key];
    }, showLoading);

    return resp;
  };

  getData = async (api, params = "", query = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = "";
        if (Object.keys(query).length > 0) {
          queryString = "?";
        }
        Object.keys(query).map((key) => {
          if (queryString === "?") {
            queryString += "&";
          }
          queryString += key;
          queryString = queryString + "=" + query[key];
        });
        let token = this.getToken();
        if (token) {
          this.instance.defaults.headers.common["Authorization"] =
            "Bearer " + token;
        } else {
          this.instance.defaults.headers.common["Authorization"] = "";
        }
        const resp = await this.instance.get(api + params + queryString, {
          headers: {
            "Content-Type": "application/json",
            "x-frontend-url":
              typeof window !== "undefined" ? window?.location.href : "server",
          },
        });
        resolve({ ...resp.data, statusCode: resp.status });
      } catch (error) {
        this.#expiredTokenMsg(error?.response?.status);
        resolve({ statusCode: error.response?.status });
        console.log(error.stack);
      }
    });
  };

  delete = async (api, params = "", query = {}, showLoading = false) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await this.#showLoading(async () => {
          let queryString = "";
          if (Object.keys(query).length > 0) {
            queryString = "?";
          }
          Object.keys(query).map((key) => {
            if (queryString == "?") {
              queryString += "&";
            }
            queryString += key;
            queryString = queryString + "=" + query[key];
          });
          let token = this.getToken();
          if (token) {
            this.instance.defaults.headers.common["Authorization"] =
              "Bearer " + token;
          }

          let key = "delete" + api + params;

          if (!onGoingRequests[key]) {
            onGoingRequests[key] = new Promise(async (resolve) => {
              const resp = await this.instance.delete(
                api + params + queryString,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              delete onGoingRequests[key];
              resolve(resp);
            });
          }

          resolve(onGoingRequests[key]);
        }, showLoading);

        return resp;
      } catch (error) {
        this.#expiredTokenMsg(error?.response?.status);
        resolve({ statusCode: error?.response?.status });
        console.log(error);
      }
    });
  };

  post = async (
    api,
    body,
    params = "",
    headers = null,
    showLoading = false
  ) => {
    const resp = await this.#showLoading(async () => {
      let key = "post" + api + params + JSON.stringify(body);
      if (!onGoingRequests[key]) {
        onGoingRequests[key] = new Promise(async (resolve) => {
          const resp = await this.postData(api, body, params, headers);
          delete onGoingRequests[key];

          resolve(resp);
        });
      }
      return onGoingRequests[key];
    }, showLoading);

    return resp;
  };

  postData = async (api, body, params = "", headers = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = this.getToken();
        if (token) {
          this.instance.defaults.headers.common["Authorization"] =
            "Bearer " + token;
        }
        const resp = await this.instance.post(api + params, body, {
          headers: {
            ...headers,
            "x-frontend-url":
              typeof window !== "undefined" ? window?.location.href : "server",
          } || {
            "Content-Type": "application/json",
            "x-frontend-url":
              typeof window !== "undefined" ? window?.location.href : "server",
          },
        });
        resolve({ ...resp.data, statusCode: resp.status });
      } catch (error) {
        this.#expiredTokenMsg(error?.response?.status);
        resolve({
          statusCode: error?.response?.status,
          data: error?.response?.data,
        });
        console.log(error);
      }
    });
  };

  put = async (
    api,
    body,
    params = "",
    headers = { "Content-Type": "application/json" },
    showLoading = false
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await this.#showLoading(async () => {
          try {
            let token = this.getToken();
            if (token) {
              this.instance.defaults.headers.common["Authorization"] =
                "Bearer " + token;
            }
            let key = "put" + api + params + JSON.stringify(body);
            if (!onGoingRequests[key]) {
              onGoingRequests[key] = new Promise(async (resolve) => {
                const resp = await this.instance.put(api + params, body, {
                  headers,
                });

                delete onGoingRequests[key];
                resolve({ ...resp.data, statusCode: resp.status });
              });
            }

            resolve(onGoingRequests[key]);
          } catch (error) {
            this.#expiredTokenMsg(error?.response?.status);
            console.log(error);
            reject({
              statusCode: error?.response?.status,
              message: error?.response?.data?.error,
            });
          }
        }, showLoading);

        return resp;
      } catch (error) {
        console.log(error);
        reject({
          statusCode: error?.response?.status,
          message: error?.response?.data?.error,
        });
      }
    });
  };

  #showLoading = async (func, showLoading) => {
    try {
      if (this.setLoading && showLoading) {
        this.setLoading(true);
      }
      const resp = await func();

      if (this.setLoading) {
        this.setLoading(false);
      }

      return resp;
    } catch (error) {}
  };

  #expiredTokenMsg = (statusCode) => {
    if (statusCode == 402) {
      toast.error("Token expired: please logout and login again.", {
        toastId: "token_expired",
        autoClose: 10000,
      });
    }
  };
}

export default new NetworkManager();
