import * as Yup from "yup";
import { TEXT } from "@/outscal-commons-frontend/FormBuilder/InputTypes";
import { EmailValidationRegex } from "@/outscal-commons-frontend/Forms/validation";

import { FaBell } from "react-icons/fa";
import { FaMedal } from "react-icons/fa";
import { MdOutlineWork } from "react-icons/md";
import { PiCertificateFill } from "react-icons/pi";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import ProTag from "@/mvcComponents/Commons/ProTag/ProTag.controller";
import { GradientText } from "@/outscal-commons-frontend/Styled";

export const RegisterPopupFormData = {
  email: {
    type: "email",
    defaultValue: "",
    placeholder: "Email",
    validation: Yup.string()
      .matches(EmailValidationRegex, "Invalid email address")
      .email("Invalid email address")
      .required("Email is required"),
    inputType: TEXT,
  },
  password: {
    type: "password",
    defaultValue: "",
    placeholder: "Password",
    validation: Yup.string().required("Required*"),
    inputType: TEXT,
  },
};

export const defaultData = {
  heading: (
    <RowDiv alignItems="center" gap="8px">
      Unlock PRO
      <ProTag fontSize="48px" />
    </RowDiv>
  ),
  subHeading: (
    <>
      Sign up and Unlock PRO <ProTag fontSize="18px" padding="3px 0 0 0" />{" "}
      benefits for
      <GradientText
        fontWeight="semiBold"
        colors={["themeBlue", "discordBlue", "themeBlue"]}
        style={{ display: "inline-block" }}
      >
        &nbsp;FREE!
      </GradientText>
    </>
  ),
  maxWidth: "500px",
  socialAuthButtonText: "Continue with",
  features: [
    {
      heading: "Job Placement Assistance",
      subHeading:
        "Get mentorship sessions, resume feedbacks and code reviews by professionals and land your next job.",
      icon: MdOutlineWork,
    },
    {
      heading: "Be The First To Know",
      subHeading:
        "Set alerts for jobs you are looking for, track your applications and stay ahead of competition.",
      icon: FaBell,
    },
    {
      heading: "Premium Course Content",
      subHeading:
        "Unlock access to exclusive courses, mock interview sessions and coding assessments to upskill yourself.",
      icon: FaMedal,
    },
    {
      heading: "Verified Certifications",
      subHeading:
        "Earn verified certificates on course completion, showcasing your achievements to potential employers.",
      icon: PiCertificateFill,
    },
  ],
};
