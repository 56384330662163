import React from "react";
import styled from "styled-components";
import { MdInfoOutline } from "react-icons/md";
import { Div } from "../../Styled";

const HeadingWrap = styled.span`
  ${(p) => `font-size:${p.theme.fontSize.lg}`};
  ${(p) => `font-weight:${p.theme.fontWeight.medium}`};
`;

const ErrorWrap = styled.span`
  ${(p) => `color:${p.theme.colors.red} !important`};
`;

const Root = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RedirectLink = styled.a`
  color: ${(p) => p.theme.colors.blue};
`;

const InfoText = styled.span`
  font-size: ${(p) => p.theme.fontSize.xs};
  color: ${(p) => p.theme.colors.textGrey};
`;
const InputHeading = ({
  heading,
  error,
  tooltip,
  link,
  linkText,
  infoText,
}) => {
  return (
    <Root>
      {error ? (
        <ErrorWrap>
          <>
            {typeof heading === "string" ? (
              <HeadingWrap>{heading}</HeadingWrap>
            ) : (
              <>{heading}</>
            )}
          </>
        </ErrorWrap>
      ) : (
        <>
          {typeof heading === "string" ? (
            <HeadingWrap>{heading}</HeadingWrap>
          ) : (
            <>{heading}</>
          )}
        </>
      )}
      {link && (
        <RedirectLink href={link} target="_blank">
          {linkText}
        </RedirectLink>
      )}
      {infoText && <InfoText>{infoText}</InfoText>}
      {tooltip && (
        <Icon data-tooltip-id="data-tip" data-tooltip-content={tooltip}>
          <MdInfoOutline size="15px" color={error ? "red" : "black"} />
        </Icon>
      )}
    </Root>
  );
};

export default InputHeading;
