import React from "react";
import { NotificationPill } from "./NotificationPill.styles";

const NotificationPillView = ({ notification }) => {
  return (
    <NotificationPill color={notification.color}>
      {notification.value}
    </NotificationPill>
  );
};

export default NotificationPillView;
