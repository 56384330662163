import styled from "styled-components";
import { FadeIn, FadeOut } from "./Animations";

export const PopUpHeading = styled.div`
  text-align: ${(p) => (p.alignText ? p.alignText : "center")};
  color: ${(p) => p.theme.colors.black};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.xl};
  font-weight: ${(p) =>
    p.fontWeight
      ? p.theme.fontWeight[p.fontWeight]
      : p.theme.fontWeight.semiBold};
  ${(p) => p.inline && `display:inline-block`};
`;

export const PopUpText = styled.div`
  text-align: ${(p) => (p.alignText ? p.alignText : "center")};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.black)};
  font-size: ${(p) => p.theme.fontSize.md};
  font-weight: ${(p) =>
    p.fontWeight ? p.theme.fontWeight[p.fontWeight] : p.theme.fontWeight.light};
  ${(p) => p.inline && `display:inline-block`};
`;

export const PopUpBG = styled.div`
  position: fixed;
  display: flex;
  z-index: ${(p) => (p?.style?.zIndex ? p?.style?.zIndex : 1300)};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  animation-name: ${(p) => (p.fadeIn ? FadeIn : FadeOut)};
  backdrop-filter: blur(3px);
  animation-duration: ${(p) =>
    p.animationDuration ? p.animationDuration : "0.1s"};
  animation-iteration-count: 1;
  height: 100%;
  background: rgba(189, 189, 189, 0.4);

  @media (max-width: 1250px) {
    position: fixed;
    height: 100vh;
    background: none;
    backdrop-filter: blur(0px);
  }
  @media (max-height: 720px) {
    position: fixed;
    background: none;
    backdrop-filter: blur(0px);
  }
`;

export const BlurBG = styled.div`
  position: fixed;
  display: none;
  z-index: ${(p) => (p?.style?.zIndex ? p?.style?.zIndex : 1300)};
  top: 0px;
  backdrop-filter: blur(3px);
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  animation-name: ${(p) => (p.fadeIn ? FadeIn : FadeOut)};
  animation-duration: ${(p) =>
    p.animationDuration ? p.animationDuration : "0.1s"};
  animation-iteration-count: 1;
  height: 100vh;
  background: rgba(189, 189, 189, 0.4);
  @media (max-width: 1250px) {
    display: flex;
  }
  @media (max-height: 720px) {
    display: flex;
  }
`;

export const PopUp = styled.div`
  margin: auto;
  z-index: 1501;
  left: 100px;
  max-height: 90%;
  width: ${(p) => (p.width ? p.width : "50%")};
  height: ${(p) => (p.height ? p.height : "50%")};
  ${(p) => p.minWidth && `min-width:${p.minWidth}`};
  ${(p) => p.maxWidth && `max-width:${p.maxWidth}`};
  ${(p) => p.minHeight && `min-height:${p.minHeight}`};
  ${(p) => p.maxHeight && `max-height:${p.maxHeight}`};
  ${(p) => p.padding && `padding:${p.padding}`};
  border-radius: ${(p) => p.theme.borderRadius[p.borderRadius]};
  background: ${(p) => p.theme.colors[p.bgColor]};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  ${(p) => `overflow: ${p.overflow ? p.overflow : "scroll"}`};
  @media (max-width: 1250px), (max-height: 720px) {
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
    height: fit-content;
  }
`;
