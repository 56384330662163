import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";

export const getLocations = async () => {
  const resp = await NetworkManager.get("/locations");
  return resp.data;
};

export const getLocationType = async () => {
  const resp = await NetworkManager.get("/location-types");
  return resp.data;
};

export const getJobType = async () => {
  const resp = await NetworkManager.get("/job-types");
  return resp.data;
};

export const getJobRole = async () => {
  const resp = await NetworkManager.get("/job-roles");
  return resp.data;
};

export const getTechStackList = async () => {
  const resp = await NetworkManager.get("/tech-stack");
  return resp.data;
};

export const getJobsSearchStatusList = async () => {
  const resp = await NetworkManager.get("/job-search-status");
  return resp.data;
};

export const getGameGenresList = async () => {
  const resp = await NetworkManager.get("/game-genres");
  return resp.data;
};

export const getJobCategoryTagList = async () => {
  const resp = await NetworkManager.get("/job-category-tags");
  return resp.data;
};

export const getCompanyList = async () => {
  const resp = await NetworkManager.get(`/company/all`);
  return resp.data;
};
