import { createSlice } from "@reduxjs/toolkit";

const jobRoleSlice = createSlice({
  name: "jobRoleList",
  initialState: [],
  reducers: {
    setJobRoleList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearJobRoleList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setJobRoleList, clearJobRoleList } = jobRoleSlice.actions;
export default jobRoleSlice.reducer;
