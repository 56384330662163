import { ApolloClient, InMemoryCache } from "@apollo/client";

class ApolloClientManager {
  constructor() {
    this.client = this.createApolloClient();
  }

  createApolloClient = () => {
    return new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_URL,
      cache: new InMemoryCache(),
    });
  };
}
export default new ApolloClientManager();
