import React from "react";
import {
  Header,
  FormSection,
  PopupWrapper,
  GradientHeading,
  FeaturesList
} from "../AuthPopups.styles";
import { ColDiv, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import PopupTemplate from "@/outscal-commons-frontend/Styled/PopupTemplate";
import { CloseIcon } from "./ProRegisterPopup.styles";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import ProRegisterPopupFormView from "./ProRegisterPopupForm.view";
import ProRegisterPopupActionsView from "./ProRegisterPopupActions.view";

const ProRegisterPopupView = ({
  heading,
  features,
  children,
  subHeading,
  hidePopups,
  showLoginPopup,
  registerPopupFormData: RegisterPopupFormData,
  onRegisterFunction,
  toggleToEmailRegister,
  setToggleToEmailRegister,
  dismissable,
  dismissOnOutsideClick,
  maxWidth,
  socialAuthButtonText,
  showLoginCta,
  analyticsIntentText,
  registerFrom,
}) => {
  return (
    <PopupTemplate
      id="register_popup"
      setShowPopUp={() => {
        dismissOnOutsideClick ? hidePopups() : () => {};
      }}
      maxWidth={maxWidth}
      width="90%"
      bgColor="white"
      height="fit-content"
      overflow="hidden"
    >
      <PopupWrapper>
        <FormSection>
          {dismissable && (
            <RowDiv style={{ position: "relative" }}>
              <CloseIcon onClick={hidePopups} />
            </RowDiv>
          )}
          <ColDiv gap="4px">
            <Header>
              <GradientHeading
                colors={["themeBlue", "discordBlue", "themeBlue"]}
              >
                {heading}
              </GradientHeading>
            </Header>
            {subHeading && <Text fontSize="sm">{subHeading}</Text>}
          </ColDiv>
          <FeaturesList
            gap="12px"
            alignItems="flex-start"
            hasFeatures={features.length > 0}
          >
            {features.map(({ heading, icon: Icon, subHeading }) => (
              <ColDiv key={heading}>
                <RowDiv alignItems="center" gap="8px">
                  <Icon style={{ fontSize: "18px", color: "#3f57cb" }} />
                  <Text fontSize="md" fontWeight="medium">
                    {heading}
                  </Text>
                </RowDiv>
                {!toggleToEmailRegister && (
                  <Text align="justify" fontSize="sm">
                    {subHeading}
                  </Text>
                )}
              </ColDiv>
            ))}
          </FeaturesList>
          {children}
          <ProRegisterPopupFormView
            toggleToEmailRegister={toggleToEmailRegister}
            registerPopupFormData={RegisterPopupFormData}
            onRegisterFunction={onRegisterFunction}
          />
          <ProRegisterPopupActionsView
            analyticsIntentText={analyticsIntentText}
            toggleToEmailRegister={toggleToEmailRegister}
            socialAuthButtonText={socialAuthButtonText}
            setToggleToEmailRegister={setToggleToEmailRegister}
            showLoginPopup={showLoginPopup}
            showLoginCta={showLoginCta}
            registerFrom={registerFrom}
          />
        </FormSection>
      </PopupWrapper>
    </PopupTemplate>
  );
};

export default ProRegisterPopupView;
