import React, { useRef, useState } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { ErrorText, Root } from "./DefaultInputStyle";
import InputHeading from "./InputHeading";
import { Div, YellowButton } from "../../Styled";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";
import * as Yup from "yup";

const ButtonWrapperDiv = styled(Div)`
  display: flex;
  flex: 1;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: ${(p) => p.theme.borderRadius.xxs} 0 0
      ${(p) => p.theme.borderRadius.xxs};
    ${(p) => `border: 2px solid ${p.theme.colors.themeYellow}`};
  }

  .MuiOutlinedInput-root {
    fieldset {
      border: none;
    }
  }

  .MuiInputBase-input {
    padding: ${(p) => p.padding};
    font-size: ${(p) => p.theme.fontSize.sm};
  }
`;

const StyledButton = styled(YellowButton)`
  width: ${(p) => p.width || "160px"};
  font-size: ${(p) => p.theme.fontSize.sm};
  box-shadow: none;
  border-radius: 0 ${(p) => p.theme.borderRadius.xxs}
    ${(p) => p.theme.borderRadius.xxs} 0;
  ${(p) => `border: 2px solid ${p.theme.colors.themeYellow}`};
`;

const InputTextFieldWithButton = ({
  name,
  label,
  placeholder,
  heading,
  error,
  value,
  width,
  type,
  tooltip,
  inputStyles = {},
  disabled,
  isVisible,
  ref,
  infoText,
  link,
  linkText,
  onButtonClick,
  buttonText,
  validation,
  analyticsText,
}) => {
  const { gap, direction = "column", padding = "8px" } = inputStyles;
  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(null);

  const handleButtonClick = async () => {
    try {
      setInputError(null);

      if (validation) {
        const schema = Yup.object().shape({
          [name]: validation,
        });

        await schema.validate({ [name]: inputRef.current.value });
      }

      onButtonClick(inputRef.current.value);
    } catch (err) {
      setInputError(err.message);
    }
  };

  return (
    <Root
      isVisible={isVisible}
      ref={ref}
      gap={gap}
      direction={direction}
      width={width}
    >
      {heading && (
        <InputHeading
          heading={heading}
          error={error}
          tooltip={tooltip}
          infoText={infoText}
          link={link}
          linkText={linkText}
        />
      )}
      <ButtonWrapperDiv>
        <StyledTextField
          padding={padding}
          id={name}
          InputProps={{
            inputProps: {
              "data-analytics": `${UIElements.INPUT}, TextField`,
            },
          }}
          name={name}
          disabled={disabled}
          variant="outlined"
          label={
            label && (
              <>
                {!heading && tooltip ? (
                  <Div
                    data-tooltip-id="data-tip"
                    data-tooltip-content={tooltip}
                  >
                    {label}
                  </Div>
                ) : (
                  <>{label}</>
                )}
              </>
            )
          }
          defaultValue={value !== null && value !== undefined ? value : ""}
          type={type}
          placeholder={placeholder}
          fullWidth
          onWheel={(e) => e.target.blur()}
          error={inputError ? true : false}
          inputRef={inputRef}
        />
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          disabled={disabled}
          data-analytics={[UIElements.BUTTON, analyticsText]}
          InputProps={{
            inputProps: {
              "data-analytics": `${UIElements.BUTTON}, InputButton`,
            },
          }}
        >
          {buttonText}
        </StyledButton>
      </ButtonWrapperDiv>
      {inputError && <ErrorText>{inputError}</ErrorText>}
    </Root>
  );
};

export default InputTextFieldWithButton;
