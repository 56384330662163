import React from "react";
import NotificationPillView from "./NotificationPill.view";

const NotificationPill = ({ notification = {} }) => {
  return notification &&
    Object.keys(notification).length > 0 &&
    notification?.isVisible ? (
    <NotificationPillView notification={notification} />
  ) : null;
};

export default NotificationPill;
