import {createSlice} from "@reduxjs/toolkit";

const companySlice = createSlice({
  name: "companyList",
  initialState: [],
  reducers: {
    setCompaniesList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearCompaniesList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setCompaniesList, clearCompaniesList } = companySlice.actions;

export default companySlice.reducer;
