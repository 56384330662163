import React, { useEffect, useState } from "react";
import InputPopupView from "./InputPopup.view";
import usePopupActions from "@/store/actions/popupAction";

const InputPopupController = ({
  formBuilder,
  onSubmit,
  heading = "Fill the following details",
  subHeading = "",
  SubmitButton,
  submitText = "submit",
}) => {
  const { hidePopups } = usePopupActions();

  return (
    <InputPopupView
      hidePopups={hidePopups}
      formData={formBuilder}
      heading={heading}
      subHeading={subHeading}
      onSubmit={onSubmit}
      SubmitButton={SubmitButton}
      submitText={submitText}
    />
  );
};

export default InputPopupController;
