import styled, { keyframes } from "styled-components";

export const Button = styled.button`
  cursor: pointer;
  width: fit-content;
  ${(p) => p.top && `margin-top: ${p.top}`};
  ${(p) => p.bottom && `margin-bottom: ${p.bottom}`};
  ${(p) => p.left && `margin-left: ${p.left}`};
  ${(p) => p.right && `margin-right: ${p.right}`};
  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};
  ${(p) => p.width && `width: ${p.width}`};
  ${(p) => p.minWidth && `min-width: ${p.minWidth}`};
  ${(p) => p.height && `height: ${p.height}`};
  background: ${(p) => p.theme.colors[p.background] || p.theme.colors.white};
  color: ${(p) => p.theme.colors[p.color] || p.theme.colors.black};
  font-size: ${(p) => p.fontSize || "1em"};
  font-weight: ${(p) => p.fontWeight || "500"};
  margin: ${(p) => p.margin || ""};
  padding: ${(p) => p.padding || "0.25em 1em"};
  border: ${(p) => p.border || "none"};
  border-radius: ${(p) => p.borderRadius || "4px"};
  transition: all 0.2s ease-in-out;
  box-shadow: ${(p) => p.shadow || "0px 3px 6px rgba(0, 0, 0, 0.16)"};
  &:hover {
    background: ${(p) => p.theme.colors[p.hoverBackground] || ""};
    color: ${(p) => p.theme.colors[p.hoverColor] || p.theme.colors.black};
    cursor: pointer;
  }
  &:active {
    background: ${(p) => p.theme.colors[p.activeBackground] || ""};
    color: ${(p) => p.activeColor || "#fff"};
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const YellowButton = styled(Button).attrs({
  background: "themeYellow",
  hoverBackground: "lightYellow",
})``;

export const BlackButton = styled(Button).attrs({
  background: "black",
  color: "white",
  hoverBackground: "#333",
  hoverColor: "white",
})``;

const pulsate = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
`;

export const AnimatedPlayButton = styled.div`
  width: 100px;
  height: 100px;
  background: ${(p) => `radial-gradient(
    ${p.theme.colors.themeYellow} 60%,
    rgba(255, 255, 255, 1) 62%
  )`};
  border-radius: 50%;
  position: relative;
  display: block;
  margin: ${(p) => (p.margin ? p.margin : "100px auto")};
  box-shadow: ${(p) => `0px 0px 10px 3px ${p.theme.colors.themeYellow}e8`};

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &::before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    animation: ${pulsate} 2s infinite;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0.75);
    top: -30%;
    left: -30%;
    background: rgba(198, 16, 0, 0);
  }
`;
