import styled from "styled-components";
import { Div } from "./LayoutUtils";

export const BoldLabel = styled(Div)`
  ${(p) => `font-size: ${p.theme.fontSize.md}`};
  ${(p) => p.fontSize && `font-size: ${p.theme.fontSize[p.fontSize]}`};
  ${(p) => p.align && `text-align: ${p.theme.align}`};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
  ${(p) => `font-weight: ${p.theme.fontWeight.bold}`};
`;
export const Text = styled.div`
  ${(p) => p.fontWeight && `font-weight: ${p.theme.fontWeight[p.fontWeight]}`};
  ${(p) =>
    p.fontSize ? `font-size: ${p.theme.fontSize[p.fontSize]}` : "18px"};
  ${(p) => p.align && `text-align: ${p.align}`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
`;
export const GradientText = styled(Text)`
  background: ${(p) => {
    const colors = p.colors || ["black"];
    const themeColors = colors.map((color) => p.theme.colors[color] || color);
    return `linear-gradient(${p.direction || "to right"}, ${themeColors.join(
      ", "
    )})`;
  }};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const Span = styled.span`
  ${(p) => p.fontWeight && `font-weight: ${p.theme.fontWeight[p.fontWeight]}`};
  ${(p) =>
    p.fontSize ? `font-size: ${p.theme.fontSize[p.fontSize]}` : "18px"};
  ${(p) => p.align && `text-align: ${p.theme.align}`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
  ${(p) => p.padding && `padding:${p.padding}`};
  ${(p) => p.margin && `margin:${p.margin}`};
`;
export const Pre = styled.pre`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
`;
export const H1 = styled.h1`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  ${(p) => p.fontSize && `font-size: ${p.theme.fontSize[p.fontSize]}`};
  ${(p) => p.align && `text-align: ${p.theme.align}`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
`;
export const H2 = styled.h2`
  ${(p) => p.fontSize && `font-size: ${p.theme.fontSize[p.fontSize]}`};
  ${(p) => p.align && `text-align: ${p.theme.align}`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
`;
export const H3 = styled.h3`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  ${(p) => p.fontSize && `font-size: ${p.theme.fontSize[p.fontSize]}`};
  ${(p) => p.align && `text-align: ${p.theme.align}`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
`;
