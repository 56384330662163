import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { Button } from "@/outscal-commons-frontend/Styled/Buttons";
import { shine } from "@/outscal-commons-frontend/Styled/Animations";
import { ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";

export const CloseIcon = styled(AiFillCloseCircle)`
  position: absolute;
  z-index: 99;
  font-size: 22px;
  color: ${(p) => p.theme.colors.textGrey};
  top: -16px;
  right: -24px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    right: -8px;
  }
`;

export const ShowFeaturesButton = styled(Button)`
  &:hover {
    transform: translateY(-3px);
  }
`;

export const FeaturesList = styled(ColDiv)`
  display: ${(p) => (p.hasFeatures ? "inherit" : "none")};
`;
