import { styled } from "styled-components";
import { Button } from "@/outscal-commons-frontend/Styled";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  position: relative;
`;

export const SubmitButton = styled(Button)`
  margin: auto;
  width: 160px;
  padding: 8px 12px;
  background-color: ${(p) => p.theme.colors.yellow};
  border-radius: ${(p) => p.theme.borderRadius.xs} !important;
  border-radius: 15px;
  font-size: ${(p) => p.theme.fontSize.lg};
`;

export const PhoneNumPopupHeading = styled.h2`
  line-height: 25px;
`;
