import * as Yup from "yup";
import { PHONE_NUMBER } from "@/outscal-commons-frontend/FormBuilder/InputTypes";
import { isValidNumber } from "@/outscal-commons-frontend/utils/validations";

export const PhoneNumFormHeading = "Complete Course Application";

export const phoneNumberFormData = {
  phone_number: {
    validation: Yup.object().test(
      "valid-phone-number",
      "Please enter a valid phone number",
      isValidNumber,
    ),

    inputType: PHONE_NUMBER,
    heading: "Primary Contact info for future communication*",
  },
};

export const defaultValues = {
  phone_number: {
    number: "",
    country_code: "IN",
    currency_code: "INR",
    calling_code: "+91",
  },
};
