import { createSlice } from "@reduxjs/toolkit";

const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: [],
    staticJob: null,
    selectedJob: { jobDetails: null, similarJobs: {} },
    jobCount: 0,
    totalJobsCount: 0,
    canLoadMoreJobs: true,
  },
  reducers: {
    setJobsState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSelectedJob: (state, action) => {
      return { ...state, selectedJob: action.payload };
    },
    clearAll: (state) => {
      return {
        jobs: [],
        selectedJob: { jobDetails: null, similarJobs: {} },
        jobCount: 0,
        staticJob: null,
        totalJobsCount: 0,
        canLoadMoreJobs: true,
      };
    },
  },
});

export const { setJobsState, setSelectedJob, clearAll } = jobsSlice.actions;
export default jobsSlice.reducer;
