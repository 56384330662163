import { useEffect, useRef } from "react";
import AnalyticsManager from "../Managers/AnalyticsManager";

export const Pages = {
  JOBS: "JOBS",
  JOB: "JOB",
  COURSES: "COURSES",
  COMPANY: "COMPANY",
  COMPANIES: "COMPANIES",
  COURSE: "COURSE",
  PRACTICE: "PRACTICE",
  PROFILE: "PROFILE",
  PROFILE_POST: "PROFILE_POST",
  PROFILE_PROFILE_DETAILS: "PROFILE_PROFILE_DETAILS",
  STATIC_JOB_PAGE: "STATIC_JOB_PAGE",
  WELCOME: "WELCOME",
  LMS: "LMS",
  SUCCESS_STORIES: "SUCCESS_STORIES",
  VIDEOS: "VIDEOS",
  LANDINGPAGE: "LANDINGPAGE",
  DAILY_QUESTION: "DAILY_QUESTION",
  DAILY_ALL_QUESTIONS: "DAILY_ALL_QUESTIONS",
  PRACTICE_PAGE: "PRACTICE_PAGE",
  ASSESSMENT_PAGE: "ASSESSMENT_PAGE",
  JOB_APPLICATIONS: "JOB_APPLICATIONS",
  INVITE: "INVITE",
  BOOKINGS_PAGE: "BOOKINGS_PAGE",
  BOOKING_DETAILS: "BOOKING_DETAILS",
  ADS: "ADS",
  MCQS: "MCQS",
  LAYOFF_SCHOLARSHIP: "LAYOFF_SCHOLARSHIP",
  ONLINE_COMPILERS: "ONLINE_COMPILERS",
  SCHOLARSHIPS: "SCHOLARSHIPS",
  SCHOLARSHIP: "SCHOLARSHIP",
};

export const UIElements = {
  BUTTON: "BUTTON",
  TEXT: "TEXT",
  INPUT: "INPUT",
  CARD: "CARD",
  LINK: "LINK",
  ICON: "ICON",
  TAB: "TAB",
  PLUG: "PLUG",
};

export const CommonElements = {
  HEADER: "HEADER",
  FOOTER: "FOOTER",
  POPUP: "POPUP",
  MOBILE_NAV: "MOBILE_NAV",
};

export const Actions = {
  open: "open",
  close: "close",
};

export const CET_NAME = {};

const useAnalyticsClickEvent = () => {
  const listenersAdded = useRef(false);
  const shouldUpdate = useRef(true);
  const elementsWithAnalyticsData = useRef();
  const getAnalyticsData = (target) => {
    let analyticsData = target.getAttribute("data-analytics");
    if (!analyticsData) {
      const parentElementWithDataAttribute = target.closest("[data-analytics]");
      if (parentElementWithDataAttribute) {
        analyticsData =
          parentElementWithDataAttribute.getAttribute("data-analytics");
      }
    }
    return analyticsData;
  };
  const onClickHandler = (event) => {
    const analyticsData = getAnalyticsData(event.target);
    const [UI, label, commonElement, CET_Name, action] =
      analyticsData.split(",");
    const page = AnalyticsManager.getPageKey();
    AnalyticsManager.trackEvent(
      page,
      UI,
      label,
      commonElement,
      CET_Name,
      action
    );
  };
  const addListeners = () => {
    listenersAdded.current = true;
    elementsWithAnalyticsData.current =
      document.querySelectorAll("[data-analytics]");
    // console.log(elementsWithAnalyticsData.current.length);
    elementsWithAnalyticsData.current.forEach((element) => {
      element.addEventListener("click", onClickHandler);
    });
  };

  const removeListeners = () => {
    if (listenersAdded.current) {
      listenersAdded.current = false;
      elementsWithAnalyticsData.current.forEach((element) => {
        element.removeEventListener("click", onClickHandler);
      });
    }
  };
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          if (shouldUpdate.current) {
            shouldUpdate.current = false;
            setTimeout(() => {
              removeListeners();
              addListeners();
              shouldUpdate.current = true;
            }, 1000);
          }
        }
      }
    });
    addListeners();
    observer.observe(document, { childList: true, subtree: true });

    return () => {};
  }, []); // The empty array means this effect runs once on mount and cleanup runs on unmount

  return null;
};
// do not use this function without confirming
export default useAnalyticsClickEvent;
