import React from "react";
import ProTagView from "./ProTag.view";
import { stylesData } from "./ProTag.model";

const ProTag = ({
  fontSize = stylesData.fontSize,
  padding = stylesData.padding,
}) => {
  return <ProTagView fontSize={fontSize} padding={padding} />;
};

export default ProTag;
