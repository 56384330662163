import React from "react";
import SocialAuthIconsView from "./SocialAuthIcons.view";

const SocialAuthIcons = ({
  variant,
  analyticsIntentText,
  showText = true,
  text = "Continue with",
  bgColor = "white",
  color = "textDarkerGrey",
  highlight = false,
  width = "",
  redirectUrl = "",
  registerFrom,
}) => {
  return (
    <SocialAuthIconsView
      width={width}
      variant={variant}
      analyticsIntentText={analyticsIntentText}
      showText={showText}
      text={text}
      isIconPill={variant === "iconpill"}
      bgColor={bgColor}
      color={color}
      highlight={highlight}
      redirectUrl={redirectUrl}
      registerFrom={registerFrom}
    />
  );
};

export default SocialAuthIcons;
