import { createSlice } from "@reduxjs/toolkit";

const locationTypeSlice = createSlice({
  name: "locationTypeList",
  initialState: [],
  reducers: {
    setLocationTypeList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearLocationTypeList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setLocationTypeList, clearLocationTypeList } =
  locationTypeSlice.actions;
export default locationTypeSlice.reducer;
