import { FaBookOpen } from "react-icons/fa";
import { IoBriefcase } from "react-icons/io5";
import { BsFillBuildingsFill } from "react-icons/bs";
import { IoPersonSharp } from "react-icons/io5";
import { HiTrophy } from "react-icons/hi2";
import { FaUserGraduate } from "react-icons/fa";
import { PiExamFill } from "react-icons/pi";

export const NAV_WIDTH = "100vw";

export const highlightedNavbarItems = {
  "/online-compilers/[languageSlug]": ["scholarship"],
};

export const mobileNavbarItems = {
  jobs: {
    id: "jobs",
    icon: IoBriefcase,
    label: "Jobs",
    link: "/jobs",
    color: "",
    isVisible: true,
    isHighlighted: false,
  },
  scholarship: {
    id: "scholarship",
    icon: FaUserGraduate,
    label: "Scholarships",
    link: "/courses#Scholarships",
    color: "",
    isVisible: true,
    isHighlighted: false,
    notification: { variant: "badge", color: "red", isVisible: false },
  },
  courses: {
    id: "courses",
    icon: FaBookOpen,
    label: "Courses",
    link: "/courses",
    color: "",
    isVisible: true,
    isHighlighted: false,
    notification: { variant: "badge", color: "red", isVisible: false },
  },
  assessment: {
    id: "assessment",
    icon: PiExamFill,
    label: "Verify Skills",
    link: "/courses#Assessment",
    color: "",
    isVisible: true,
    isHighlighted: false,
    notification: { variant: "badge", color: "red", isVisible: false },
  },
  placements: {
    id: "placements",
    icon: HiTrophy,
    label: "Placements",
    link: "/courses#Placements",
    color: "",
    isVisible: true,
    isHighlighted: false,
  },
  companies: {
    id: "companies",
    icon: BsFillBuildingsFill,
    label: "Companies",
    link: "/companies",
    color: "",
    isVisible: false,
    isHighlighted: false,
  },
  profile: {
    id: "profile",
    icon: IoPersonSharp,
    label: "Profile",
    link: "/profile",
    color: "",
    isVisible: true,
    isHighlighted: false,
    notification: { variant: "badge", color: "red", isVisible: false },
  },
};
