import React from "react";
import PrivacyPolicyView from "../PrivacyPolicy.view";
import { AnimatedForm, SubmitButton } from "../AuthPopups.styles";
import dynamic from "next/dynamic";
const FormBuilder = dynamic(() => import('@/outscal-commons-frontend/FormBuilder/FormUI'), {
  ssr: false
});;

const ProRegisterPopupFormView = ({
  toggleToEmailRegister,
  registerPopupFormData,
  onRegisterFunction,
}) => {
  return (
    <AnimatedForm fade={toggleToEmailRegister}>
      <FormBuilder
        Inputs={registerPopupFormData}
        inputStyles={{ direction: "column" }}
        formStyles={{ gap: "24px" }}
        onSubmit={(e) => onRegisterFunction(e)}
        SubmitButton={SubmitButton}
        submitText="Agree & Join"
        CustomComponent={<PrivacyPolicyView />}
      />
    </AnimatedForm>
  );
};

export default ProRegisterPopupFormView;
