import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userProfile",
  initialState: {
    userJobProfile: null,
    userPreference: null,
    userResume: null,
    userDetails: null,
    userCourseStatus: null,
    notifications: null,
    userScholarships: null,
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.userJobProfile = action.payload;
      return state;
    },
    setUserPreference: (state, action) => {
      state.userPreference = action.payload;
      return state;
    },
    setUserResume: (state, action) => {
      state.userResume = action.payload;
      return state;
    },
    setUserSavedDetails: (state, action) => {
      state.savedDetails = action.payload;
      return state;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      return state;
    },
    setUserCourseStatus: (state, action) => {
      state.userCourseStatus = action.payload;
      return state;
    },
    setUserNotifications: (state, action) => {
      state.notifications = action.payload;
      return state;
    },
    setUserScholarships: (state, action) => {
      state.userScholarships = action.payload;
      return state;
    },
    clearUserDetails: (state, action) => {
      return {
        userJobProfile: null,
        userPreference: null,
        userResume: null,
        userDetails: null,
        userCourseStatus: null,
        notifications: null,
      };
    },
  },
});

export const {
  setUserProfile,
  setUserPreference,
  setUserResume,
  setUserSavedDetails,
  setUserDetails,
  setUserCourseStatus,
  setUserNotifications,
  clearUserDetails,
  setUserScholarships,
} = userSlice.actions;
export default userSlice.reducer;
