import { createSlice } from "@reduxjs/toolkit";

const gameGenreSlice = createSlice({
  name: "gameGenreList",
  initialState: [],
  reducers: {
    setGameGenreList: (state, action) => {
      state = action.payload;
      return state;
    },
    clearGameGenreList: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setGameGenreList, clearGameGenreList } = gameGenreSlice.actions;
export default gameGenreSlice.reducer;
