import React from "react";
import { Div, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import {
  SkillActionCTA,
  SkillActionLink,
  SkillText,
} from "./AddSkillCard.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import usePopupActions from "@/store/actions/popupAction";

const AddSkillCardView = ({
  techStack,
  onCTAClick,
  actionCTA,
  actionLink,
  icon,
}) => {
  const { hidePopups } = usePopupActions();
  const Icon = icon?.icon;
  return (
    <RowDiv justifyContent="space-between" alignItems="center">
      <RowDiv alignItems="center" gap="8px">
        {Icon && (
          <Div>
            <Icon
              size={icon.size}
              color={icon.color}
              style={{ marginTop: "7px" }}
            />
          </Div>
        )}
        <SkillText>{techStack.label}</SkillText>
      </RowDiv>
      {actionCTA && (
        <SkillActionCTA
          textColor={actionCTA.color}
          bgColor={actionCTA.bgColor}
          onClick={() => onCTAClick(techStack)}
          data-analytics={[UIElements.BUTTON, `addSkillPopup`]}
        >
          {actionCTA.text}
        </SkillActionCTA>
      )}
      {actionLink && (
        <SkillActionLink
          href={actionLink.link}
          onClick={hidePopups}
          data-analytics={[UIElements.LINK, `verifySkillPopup`]}
        >
          <SkillActionCTA
            textColor={actionLink.color}
            bgColor={actionLink.bgColor}
          >
            {actionLink.text}
          </SkillActionCTA>
        </SkillActionLink>
      )}
    </RowDiv>
  );
};

export default AddSkillCardView;
