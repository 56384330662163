import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { highlightedNavbarItems } from "./GeneralNavBar/mobileNavbar.model";
import MobileNavBarController from "./GeneralNavBar/mobileNavbar.controller";
import { isContentCoursePage } from "@/Logic/ServerLogic/PageDataControllers/CourseDetails/CourseDetailsPageData.model";
const CoursesNavbarController = dynamic(() =>
  import("./CoursesNavBar/CoursesNavbar.controller")
);

const MobileNavbarService = () => {
  const router = useRouter();
  const NullElement = () => <></>;
  const PageNavBars = {
    "/job-listing/[jobSlug]": NullElement,
    "/welcome/[ftue]": NullElement,
    "/job-apply-form/[jobSlug]": NullElement,
    "/courses": CoursesNavbarController,
    "/scholarships": CoursesNavbarController,
    "/course-listing/gamedev": NullElement,
    "/course-listing/gamedev-it-yogesh": NullElement,
    "/course-listing/gamedev-it-soumya": NullElement,
    "/course-listing/gamedev-qa": NullElement,
    "/course-listing/gamedev-qa-pranay": NullElement,
    "/[username]": MobileNavBarController,
  };

  let NavBar = PageNavBars[router.basePath || router.pathname];

  let highlightedItems =
    highlightedNavbarItems[router.basePath || router.pathname];
  return (
    <>
      {isContentCoursePage(router.asPath) ? null : NavBar !== undefined ? (
        <NavBar />
      ) : (
        <MobileNavBarController highlightedItems={highlightedItems} />
      )}
    </>
  );
};

export default MobileNavbarService;
