import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setTechStackList,
  clearTechStackList,
} from "@/store/reducers/techStackReducer";
import OldOptionsEnum from "@/outscal-commons-frontend/Enums/OldOptionsEnum";

import { useState } from "react";
import { getTechStackList } from "./utils";
var apiInProgress = false;
const useTechStackActions = () => {
  const dispatch = useDispatch();
  const techStackList = useSelector((state) => state.techStackList);
  const [techStackDisplayNameMapping, setTechStackDisplayNameMapping] =
    useState({});

  const setTechStack = (skills) => {
    dispatch(setTechStackList(skills));
  };
  const clearTechStack = () => {
    dispatch(clearTechStackList());
  };

  const getTechStack = async () => {
    apiInProgress = true;
    const resp = await getTechStackList();
    const list = OldOptionsEnum.returnDisplayList(resp);
    apiInProgress = false;
    setTechStack(list);
  };

  const populateTechStackDisplayNameMapping = () => {
    let techStackObj = {};
    techStackList.forEach((techStack) => {
      techStackObj[techStack.id] = techStack.label;
    });

    setTechStackDisplayNameMapping(techStackObj);
  };

  useEffect(() => {
    if (techStackList.length > 0) {
      populateTechStackDisplayNameMapping();
    }
  }, [techStackList]);
  useEffect(() => {
    if (techStackList.length === 0 && !apiInProgress) {
      setTimeout(() => {
        getTechStack();
      }, 1000);
    }
  }, []);
  return {
    setTechStack,
    clearTechStack,
    techStackList,
    techStackDisplayNameMapping,
  };
};

export default useTechStackActions;
