import { Div } from "../../Styled/LayoutUtils";
import styled from "styled-components";

export const Root = styled(Div)`
  ${(p) => (p.isVisible === false ? `display:none` : `display:flex`)};
  ${(p) => p.width && `width:${p.width}`};
  ${(p) => (p.gap ? `gap : ${p.gap}` : `gap:10px`)};
  ${(p) =>
    p.direction ? `flex-direction : ${p.direction}` : `flex-direction:row`};
`;

export const HeadingWrap = styled.span`
  ${(p) => `font-size:${p.theme.fontSize.xxxl}`};
  ${(p) => `font-weight:${p.theme.fontWeight.medium}`};
`;

export const ErrorText = styled.p`
  ${(p) => `color:${p.theme.colors.muiRed}`};
  ${(p) => `font-weight:${p.theme.fontWeight.medium}`};
  text-align: left;
  margin-top: 0px;
`;

export const InputErrorDiv = styled(Div)`
  flex: 2;
`;

export const ClearButton = styled.button`
  ${(p) => `background:${p.theme.colors.white}`};
  ${(p) => `color:${p.theme.colors.black}`};
  ${(p) => `border:1px solid ${p.theme.colors.borderGray}`};
  ${(p) => `font-size:${p.theme.fontSize.md}`};
  ${(p) => `border-radius:${p.theme.borderRadius.xs}`};
  ${(p) => `font-weight:${p.theme.fontWeight.medium}`};
  cursor: pointer;
  padding: 0.5rem 2rem;
  &:hover {
    ${(p) => `background:${p.theme.colors.black}`};
    ${(p) => `color:${p.theme.colors.white}`};
  }
`;

export const SubmitButton = styled.button`
  ${(p) => `background:${p.theme.colors.yellow}`};
  ${(p) => `color:${p.theme.colors.black}`};
  ${(p) => `background:${p.theme.colors.yellow}`};
  ${(p) => `color:${p.theme.colors.black}`};
  ${(p) => `border:1px solid ${p.theme.colors.borderGray}`};
  ${(p) => `font-size:${p.theme.fontSize.md}`};
  ${(p) => `border-radius:${p.theme.borderRadius.xs}`};
  ${(p) => `font-weight:${p.theme.fontWeight.medium}`};
  cursor: pointer;
  padding: 0.5rem 2rem;
  &:hover {
    ${(p) => `background:${p.theme.colors.white}`};
    ${(p) => `color:${p.theme.colors.yellow}`};
  }
`;
