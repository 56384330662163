import { useDispatch, useSelector } from "react-redux";
import {
  clearScholarshipData,
  setScholarshipData,
} from "@/store/reducers/scholarshipReducer";

export const plugState = {
  reminder: "REMINDER",
  inform: "INFORM",
};

export const plugStateShowDetails = {
  [plugState.reminder]: {
    message: "Continue completing your scholarship application",
    link: "/courses#Scholarships",
  },
  [plugState.inform]: {
    message: "100 % scholarship on Game Development Courses",
    link: "/courses#Scholarships",
  },
};

const useScholarshipActions = () => {
  const dispatch = useDispatch();
  const scholarshipPlugInfo = useSelector((state) => state.scholarshipPlugInfo);

  const setReminderScholarshipPlug = ({ scholarshipSlug }) => {
    let plugData = {
      plugMessage: plugStateShowDetails[plugState.reminder].message,
      plugState: plugState.reminder,
      plugLink: `/scholarship/${scholarshipSlug}`,
    };

    dispatch(setScholarshipData(plugData));
  };

  const clearScholarshipInfo = () => {
    dispatch(clearScholarshipData());
  };

  return {
    setReminderScholarshipPlug,
    clearScholarshipInfo,
    scholarshipPlugInfo,
  };
};

export default useScholarshipActions;
